import { API } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";

export const getAllPanteones = async (user) => {
  let url = API + "spaces/getPanteones/";

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const AddPanteon = async (user, values) => {
  let url = API + "spaces/nuevoPanteon/";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const AddEspaciosAPanteon = async (user, values) => {
  let url = API + "spaces/espacios/nuevoEspacio/";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const PostAddPersonaAEspaciosAPI = async (user, values) => {
  let url = API + "spaces/espacios/asignarPersonaEspacio/";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);

    toast.error(
      "Hubo un error al ejecutar la acción: " + error.response.data.message
    );
    return error;
  }
};

export const PutEditPersonaAEspaciosAPI = async (user, values) => {
  let url = API + "spaces/espacios/editarPersonaEspacio/" + values.id;

  let finalValues = values;
  delete finalValues.id;

  try {
    const response = await axios.put(url, finalValues, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);

    toast.error(
      "Hubo un error al ejecutar la acción: " + error.response.data.message
    );
    return error;
  }
};

export const GetEspaciosDePanteonAPI = async (user, id) => {
  let url = API + "spaces/espacios/getEspaciosPanteon/" + id;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const GetEspaciosDePanteonPagosMantenimientoAPI = async (user, id) => {
  let url =
    API + "spaces/espacios/getEspaciosFiltro/?tipoEspacio=0&idpanteon=" + id;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const PostAsignarContratoByIdEspacioAPI = async (user, values) => {
  let url = API + "spaces/espacios/asignarEspacioContrato/";

  debugger;

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.error(error);
    return error;
  }
};

export const DeleteEliminarPersonaDeEspacioAPI = async (user, values) => {
  let url = API + "spaces/espacios/eliminarPersonaEspacio/" + values;

  try {
    const response = await axios.delete(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.error(error);
    return error;
  }
};

export const GetAnaliticaPorPanteonAPI = async (user, values) => {
  let url =
    API +
    "spaces/espacios/reporteOcupacion/?idtipoEspacio=0&idpanteon=" +
    values;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.error(error);
    return error;
  }
};
