import React from "react";
import {
  calcularMantenimientoContrato,
  getDetailContract,
  getListadoContratosPorUbicacion,
} from "../../API/ApiContratos";
import { AuthContext } from "../../auth/authContext";
import { BuscadorDeContratosParaPagoComponent } from "../BuscadorDeContratosParaPagoComponent/BuscadorDeContratosParaPagoComponent";

import Chip from "@mui/material/Chip";
import { getPreciosMantenimientoAPI } from "../../API/ApiMantenimientos";
import { getAllEspacios } from "../../API/ApiEspacios";
import { AddPaymentMantenimientoComponentCrudSegundaVersion } from "../AddPaymentMantenimientoComponentCrudSegundaVersion/AddPaymentMantenimientoComponentCrudSegundaVersion";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import EstadoDeCuentaPagosMantenimientoComponent from "../EstadoDeCuentaPagosMantenimientoComponent/EstadoDeCuentaPagosMantenimientoComponent";
import { BuscadorDeEspaciosPorNombreComponent } from "../BuscadorDeEspaciosPorNombreComponent/BuscadorDeEspaciosPorNombreComponent";

export const AddPaymentMantenimientoTerceraVersion = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [numeroContratoEncontrado, setNumeroContratoEncontrado] =
    React.useState({});

  const [finalData, setFinalData] = React.useState([]);
  const [finalDataEspacios, setFinalDataEspacios] = React.useState([]);

  const [finalDataDetalle, setFinalDataDetalle] = React.useState({});
  const [finalDataPreciosMantenimiento, setFinalDataPreciosMantenimiento] =
    React.useState([]);

  const [finalDataDetallePagos, setFinalDataDetallePagos] = React.useState({});

  const [crudAction, setCrudAction] = React.useState({});

  const [loading, setLoading] = React.useState(true);

  const [finalReportePorEspacio, setFinalReportePorEspacio] = React.useState(
    []
  );

  const [abrirModalPagos, setAbrirModalPagos] = React.useState(false);

  const [tipoEspacioSeleccionado, setTipoEspacioSeleccionado] =
    React.useState(0);

  const [ordenarPor, setOrdenarPor] = React.useState("");

  const [estadoDeCuenta, setEstadoDeCuenta] = React.useState([]);

  const [valueTab, setValueTab] = React.useState("1");

  const tabHandleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  React.useEffect(() => {
    const loadingData = async () => {
      if (Object.keys(numeroContratoEncontrado).length !== 0) {
        
        setLoading(true);
        setAbrirModalPagos(true);
        await getDetailContract(user, numeroContratoEncontrado.id).then(
          async (resultado) => {
            if (resultado.status == 200) {
              setFinalDataDetalle(resultado.data);
            } else {
              setFinalDataDetalle([]);
            }

            await calcularMantenimientoContrato(
              user,
              numeroContratoEncontrado.id
            ).then((resultado) => {
              if (resultado.status == 200) {
                var resultadosFinalesPagosDesglose = [];

                if (
                  typeof resultado.data?.duplex?.pagosDesglose !== "undefined"
                ) {
                  for (const pago of resultado.data?.duplex?.pagosDesglose) {
                    resultadosFinalesPagosDesglose.push(pago);
                  }
                }

                if (
                  typeof resultado.data?.mausoleo?.pagosDesglose !== "undefined"
                ) {
                  for (const pago of resultado.data?.mausoleo?.pagosDesglose) {
                    resultadosFinalesPagosDesglose.push(pago);
                  }
                }

                if (
                  typeof resultado.data?.murales?.pagosDesglose !== "undefined"
                ) {
                  for (const pago of resultado.data?.murales?.pagosDesglose) {
                    resultadosFinalesPagosDesglose.push(pago);
                  }
                }

                if (
                  typeof resultado.data?.uniplex?.pagosDesglose !== "undefined"
                ) {
                  for (const pago of resultado.data?.uniplex?.pagosDesglose) {
                    resultadosFinalesPagosDesglose.push(pago);
                  }
                }

                if (
                  typeof resultado.data?.capillas?.pagosDesglose !== "undefined"
                ) {
                  for (const pago of resultado.data?.capillas?.pagosDesglose) {
                    resultadosFinalesPagosDesglose.push(pago);
                  }
                }

                setEstadoDeCuenta(resultadosFinalesPagosDesglose);

                setFinalDataDetallePagos(resultado.data);
              }
            });

            getPreciosMantenimientoAPI(user).then((resultado) => {
              if (resultado.status === 200) {
                setFinalDataPreciosMantenimiento(resultado.data);
              }
            });

            getAllEspacios(user).then((resultado) => {
              if (resultado.status == 200) {
                console.log(resultado.data);
                setFinalDataEspacios(resultado.data);
              } else {
                setFinalDataEspacios([]);
              }
            });

            setLoading(false);
          }
        );

        getListadoContratosPorUbicacion(user, tipoEspacioSeleccionado).then(
          (resultado) => {
            if (resultado.status == 200) {
              console.table(resultado.data);
              setFinalReportePorEspacio(resultado.data);
              setLoading(false);
            } else {
              setFinalReportePorEspacio([]);
              setLoading(false);
            }
          }
        );
      } else {
        setLoading(true);
        getAllEspacios(user).then((resultado) => {
          if (resultado.status == 200) {
            setFinalDataEspacios(resultado.data);
            setLoading(false);
          } else {
            setFinalDataEspacios([]);
            setLoading(false);
          }
        });
      }
    };

    loadingData();
  }, [numeroContratoEncontrado, crudAction]);

  React.useEffect(() => {
    if (tipoEspacioSeleccionado == 0) {
      return;
    } else {
      setLoading(true);

      
      getListadoContratosPorUbicacion(user, tipoEspacioSeleccionado).then(
        (resultado) => {
          if (resultado.status == 200) {
            console.table(resultado.data);
            setFinalReportePorEspacio(resultado.data);
            setLoading(false);
          } else {
            setFinalReportePorEspacio([]);
            setLoading(false);
          }
        }
      );
    }
  }, [tipoEspacioSeleccionado]);

  React.useEffect(() => {
    let hola = finalReportePorEspacio;

    
    if (ordenarPor == "") {
      return;
    } else if (ordenarPor == "asc") {
      //ASC
      let final = hola.sort((a, b) => a.porcentage - b.porcentage);

      setFinalReportePorEspacio(final);
    } else if (ordenarPor == "desc") {
      //desc
      let final = hola.sort((a, b) => b - a);
      setFinalReportePorEspacio(final);
    }
  }, [ordenarPor, numeroContratoEncontrado, crudAction]);

  const handleChange = (event) => {
    setTipoEspacioSeleccionado(event.target.value);
  };

  return (
    <React.Fragment>
      <div className="container-fluid mt-3">
        <div className="row d-print-none">
          <div className="col-sm-12">
            <h5>Selecciona Contrato</h5>
            <BuscadorDeContratosParaPagoComponent
              numeroContratoEncontrado={numeroContratoEncontrado}
              setNumeroContratoEncontrado={setNumeroContratoEncontrado}
            />
          </div>
          <div className="col-sm-12 d-none">
            <h5>Buscar por nombre de finado, contrato o titular de contrato</h5>
            <BuscadorDeEspaciosPorNombreComponent
              numeroContratoEncontrado={numeroContratoEncontrado}
              setNumeroContratoEncontrado={setNumeroContratoEncontrado}
            />
          </div>
        </div>
      </div>

      {abrirModalPagos == true ? (
        <React.Fragment>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mt-4 mb-2 d-print-none">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setAbrirModalPagos(false);
                  }}
                >
                  <KeyboardArrowLeftIcon /> Regresar
                </Button>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={valueTab}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={tabHandleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab
                          label="Información"
                          value="1"
                          className="d-print-none"
                        />
                        <Tab
                          label="Registro de Pagos"
                          value="2"
                          className="d-print-none"
                        />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <React.Fragment>
                        {loading == true ? (
                          <>
                            <div className="container-fluid mt-3">
                              <div className="row">
                                <div className="col-12">
                                  <div className="card">
                                    <div className="card-header text-center">
                                      Cargando Información
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="container-fluid mt-3">
                              <div className="row">
                                <div className="col-12">
                                  <div className="card">
                                    <div className="card-header">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                          Contrato -{" "}
                                          <strong>
                                            {" "}
                                            {
                                              finalDataDetallePagos?.contrato
                                                ?.serie
                                            }
                                            {
                                              finalDataDetallePagos?.contrato
                                                ?.noContrato
                                            }
                                          </strong>{" "}
                                          <br />{" "}
                                          {
                                            finalDataDetallePagos?.contrato
                                              ?.titular
                                          }
                                        </div>
                                        <div>
                                          {finalDataDetallePagos?.contrato
                                            ?.status === 1 ? (
                                            <React.Fragment>
                                              <Chip
                                                label="Activo"
                                                size="small"
                                                color="primary"
                                              />
                                            </React.Fragment>
                                          ) : null}
                                          {finalDataDetallePagos?.contrato
                                            ?.status === 2 ? (
                                            <React.Fragment>
                                              <Chip
                                                label="Cancelado"
                                                size="small"
                                                color="primary"
                                              />
                                            </React.Fragment>
                                          ) : null}
                                          {finalDataDetallePagos?.contrato
                                            ?.status === 3 ? (
                                            <React.Fragment>
                                              <Chip
                                                label="Inactivo"
                                                size="small"
                                                color="primary"
                                              />
                                            </React.Fragment>
                                          ) : null}
                                          {finalDataDetallePagos?.contrato
                                            ?.status === 4 ? (
                                            <React.Fragment>
                                              <Chip
                                                label="Otorgado"
                                                size="small"
                                                color="primary"
                                              />
                                            </React.Fragment>
                                          ) : null}
                                          {finalDataDetallePagos?.contrato
                                            ?.status === 5 ? (
                                            <React.Fragment>
                                              <Chip
                                                label="Pagado"
                                                size="small"
                                                color="primary"
                                              />
                                            </React.Fragment>
                                          ) : null}
                                          {finalDataDetallePagos?.contrato
                                            ?.status === 6 ? (
                                            <React.Fragment>
                                              <Chip
                                                label="Restructurado"
                                                size="small"
                                                color="primary"
                                              />
                                            </React.Fragment>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-between align-items-center">
                                        {" "}
                                        <span>
                                          <br />
                                          {finalDataDetalle?.espacios?.duplex?.map(
                                            (duplex, index) => (
                                              <React.Fragment key={index}>
                                                {duplex.nombreJardin} -{" "}
                                                {duplex.noPosicion}
                                                <br />
                                                Espacio 1:{" "}
                                                {duplex.personaEspacio1 == null
                                                  ? "Disponible"
                                                  : duplex.personaEspacio1}
                                                <br />
                                                Espacio 2:{" "}
                                                {duplex.personaEspacio2 == null
                                                  ? "Disponible"
                                                  : duplex.personaEspacio2}
                                              </React.Fragment>
                                            )
                                          )}

                                          {finalDataDetalle?.espacios?.capillas.map(
                                            (capilla, index) => (
                                              <React.Fragment key={index}>
                                                <br />
                                                Espacio {index + 1}:{"  "}
                                                {capilla.persona == null
                                                  ? "Disponible"
                                                  : capilla.persona}
                                              </React.Fragment>
                                            )
                                          )}
                                          <br />
                                          <br />

                                          {finalDataDetalle?.espacios?.murales.map(
                                            (mural, index) => (
                                              <React.Fragment key={index}>
                                                <br />
                                                Espacio {index + 1}:{"  "}
                                                {mural.persona == null
                                                  ? "Disponible"
                                                  : mural.persona}
                                              </React.Fragment>
                                            )
                                          )}
                                          <br />
                                          <br />

                                          {finalDataDetalle?.espacios?.uniplex.map(
                                            (uniplex, index) => (
                                              <React.Fragment key={index}>
                                                <br />
                                                Espacio {index + 1}:{"  "}
                                                {uniplex.persona == null
                                                  ? "Disponible"
                                                  : uniplex.persona}
                                              </React.Fragment>
                                            )
                                          )}
                                        </span>{" "}
                                        <small>
                                          Saldo Contrato:{" "}
                                          <Chip
                                            label={
                                              "$ " +
                                              finalDataDetalle?.etapaDos[0]
                                                ?.saldo
                                            }
                                            size="small"
                                            color="success"
                                          />
                                        </small>
                                      </div>
                                    </div>
                                    <div className="card-body">
                                      {/* Capillas Si extisten las pinto*/}
                                      {Array.isArray(
                                        finalDataDetallePagos?.capillas
                                      ) ? null : (
                                        <>
                                          <h6>Capillas</h6>
                                          <table className="table table-sm">
                                            <thead>
                                              <tr>
                                                <th scope="col">Año</th>
                                                <th scope="col">Primer Año</th>
                                                <th scope="col">Estado</th>
                                                <th scope="col">
                                                  Monto a pagar
                                                </th>
                                                <th scope="col">
                                                  Monto pagado
                                                </th>
                                                <th scope="col">Acciones</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {finalDataDetallePagos?.capillas?.Pagado?.map(
                                                (pagorealizado, index) => (
                                                  <React.Fragment key={index}>
                                                    <tr className="table-success">
                                                      <td>
                                                        {pagorealizado.anoPago}
                                                      </td>
                                                      <td>
                                                        {pagorealizado.primerAno ===
                                                        true ? (
                                                          <>Sí</>
                                                        ) : (
                                                          <>No</>
                                                        )}
                                                      </td>
                                                      <td>
                                                        {pagorealizado.estado}
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {pagorealizado.primerAno ===
                                                        true ? (
                                                          <>
                                                            {" "}
                                                            {
                                                              pagorealizado.precioPrimerAno
                                                            }
                                                          </>
                                                        ) : (
                                                          <>
                                                            {" "}
                                                            {
                                                              pagorealizado.precioLista
                                                            }
                                                          </>
                                                        )}
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {
                                                          pagorealizado.montoPagado
                                                        }
                                                      </td>
                                                      <td>
                                                        {pagorealizado.estado ===
                                                        "Pago parcial" ? (
                                                          <div
                                                            className="btn btn-sm btn-warning"
                                                            onClick={() => {
                                                              let holis =
                                                                finalDataPreciosMantenimiento;

                                                              let buscandoPrecioYEspacio =
                                                                finalDataPreciosMantenimiento.find(
                                                                  (precio) =>
                                                                    precio.espacio ==
                                                                      "Capilla" &&
                                                                    precio.ano ==
                                                                      pagorealizado.anoPago
                                                                );

                                                              let resultado =
                                                                Object.assign(
                                                                  {},
                                                                  pagorealizado,
                                                                  {
                                                                    tipo: "capilla",
                                                                  }
                                                                );

                                                              setCrudAction({
                                                                action:
                                                                  "abrirPago",
                                                                data: resultado,
                                                                precioMantenimiento:
                                                                  buscandoPrecioYEspacio,
                                                                datosContrato:
                                                                  finalDataDetalle,
                                                                preciosMantenimiento:
                                                                  finalDataPreciosMantenimiento,
                                                              });
                                                            }}
                                                          >
                                                            Pagar
                                                          </div>
                                                        ) : null}
                                                      </td>
                                                    </tr>
                                                  </React.Fragment>
                                                )
                                              )}
                                            </tbody>
                                            <tbody>
                                              {finalDataDetallePagos?.capillas?.PorPagar?.map(
                                                (pagorporrealizar, index) => (
                                                  <React.Fragment key={index}>
                                                    <tr className="table-warning">
                                                      <td>
                                                        {
                                                          pagorporrealizar.anoPago
                                                        }
                                                      </td>
                                                      <td>
                                                        {pagorporrealizar.primerAno ===
                                                        true ? (
                                                          <>Sí</>
                                                        ) : (
                                                          <>No</>
                                                        )}
                                                      </td>
                                                      <td>
                                                        {
                                                          pagorporrealizar.estado
                                                        }
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {pagorporrealizar.primerAno ===
                                                        true ? (
                                                          <>
                                                            {" "}
                                                            {
                                                              pagorporrealizar.precioPrimerAno
                                                            }
                                                          </>
                                                        ) : (
                                                          <>
                                                            {" "}
                                                            {
                                                              pagorporrealizar.precioLista
                                                            }
                                                          </>
                                                        )}
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {
                                                          pagorporrealizar.montoPagado
                                                        }
                                                      </td>
                                                      <td>
                                                        <div
                                                          className="btn btn-sm btn-warning"
                                                          onClick={() => {
                                                            let holis =
                                                              finalDataPreciosMantenimiento;

                                                            let buscandoPrecioYEspacio =
                                                              finalDataPreciosMantenimiento.find(
                                                                (precio) =>
                                                                  precio.espacio ==
                                                                    "Capilla" &&
                                                                  precio.ano ==
                                                                    pagorporrealizar.anoPago
                                                              );

                                                            let resultado =
                                                              Object.assign(
                                                                {},
                                                                pagorporrealizar,
                                                                {
                                                                  tipo: "capilla",
                                                                }
                                                              );

                                                            setCrudAction({
                                                              action:
                                                                "abrirPago",
                                                              data: resultado,
                                                              precioMantenimiento:
                                                                buscandoPrecioYEspacio,
                                                              datosContrato:
                                                                finalDataDetalle,
                                                              preciosMantenimiento:
                                                                finalDataPreciosMantenimiento,
                                                            });
                                                          }}
                                                        >
                                                          Pagar
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </React.Fragment>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </>
                                      )}

                                      {/* Mausoleos Si extisten las pinto*/}
                                      {Array.isArray(
                                        finalDataDetallePagos?.mausoleo
                                      ) ? null : (
                                        <>
                                          <h6>Mausoleo</h6>
                                          <table className="table table-sm">
                                            <thead>
                                              <tr>
                                                <th scope="col">Año</th>
                                                <th scope="col">Primer Año</th>
                                                <th scope="col">Estado</th>
                                                <th scope="col">
                                                  Monto a pagar
                                                </th>
                                                <th scope="col">
                                                  Monto pagado
                                                </th>
                                                <th scope="col">Acciones</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {finalDataDetallePagos?.mausoleo?.Pagado?.map(
                                                (pagorealizado, index) => (
                                                  <React.Fragment key={index}>
                                                    <tr className="table-success">
                                                      <td>
                                                        {pagorealizado.anoPago}
                                                      </td>
                                                      <td>
                                                        {pagorealizado.primerAno ===
                                                        true ? (
                                                          <>Sí</>
                                                        ) : (
                                                          <>No</>
                                                        )}
                                                      </td>
                                                      <td>
                                                        {pagorealizado.estado}
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {pagorealizado.primerAno ===
                                                        true ? (
                                                          <>
                                                            {" "}
                                                            {
                                                              pagorealizado.precioPrimerAno
                                                            }
                                                          </>
                                                        ) : (
                                                          <>
                                                            {" "}
                                                            {
                                                              pagorealizado.precioLista
                                                            }
                                                          </>
                                                        )}
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {
                                                          pagorealizado.montoPagado
                                                        }
                                                      </td>
                                                      <td>
                                                        {pagorealizado.estado ===
                                                        "Pago parcial" ? (
                                                          <div
                                                            className="btn btn-sm btn-warning"
                                                            onClick={() => {
                                                              let holis =
                                                                finalDataPreciosMantenimiento;

                                                              let buscandoPrecioYEspacio =
                                                                finalDataPreciosMantenimiento.find(
                                                                  (precio) =>
                                                                    precio.espacio ==
                                                                      "Mausoleo" &&
                                                                    precio.ano ==
                                                                      pagorealizado.anoPago
                                                                );

                                                              let resultado =
                                                                Object.assign(
                                                                  {},
                                                                  pagorealizado,
                                                                  {
                                                                    tipo: "capilla",
                                                                  }
                                                                );

                                                              setCrudAction({
                                                                action:
                                                                  "abrirPago",
                                                                data: resultado,
                                                                precioMantenimiento:
                                                                  buscandoPrecioYEspacio,
                                                                datosContrato:
                                                                  finalDataDetalle,
                                                                preciosMantenimiento:
                                                                  finalDataPreciosMantenimiento,
                                                              });
                                                            }}
                                                          >
                                                            Pagar
                                                          </div>
                                                        ) : null}
                                                      </td>
                                                    </tr>
                                                  </React.Fragment>
                                                )
                                              )}
                                            </tbody>
                                            <tbody>
                                              {finalDataDetallePagos?.mausoleo?.PorPagar?.map(
                                                (pagorporrealizar, index) => (
                                                  <React.Fragment key={index}>
                                                    <tr className="table-warning">
                                                      <td>
                                                        {
                                                          pagorporrealizar.anoPago
                                                        }
                                                      </td>
                                                      <td>
                                                        {pagorporrealizar.primerAno ===
                                                        true ? (
                                                          <>Sí</>
                                                        ) : (
                                                          <>No</>
                                                        )}
                                                      </td>
                                                      <td>
                                                        {
                                                          pagorporrealizar.estado
                                                        }
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {pagorporrealizar.primerAno ===
                                                        true ? (
                                                          <>
                                                            {" "}
                                                            {
                                                              pagorporrealizar.precioPrimerAno
                                                            }
                                                          </>
                                                        ) : (
                                                          <>
                                                            {" "}
                                                            {
                                                              pagorporrealizar.precioLista
                                                            }
                                                          </>
                                                        )}
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {
                                                          pagorporrealizar.montoPagado
                                                        }
                                                      </td>
                                                      <td>
                                                        <div
                                                          className="btn btn-sm btn-warning"
                                                          onClick={() => {
                                                            let holis =
                                                              finalDataPreciosMantenimiento;

                                                            let buscandoPrecioYEspacio =
                                                              finalDataPreciosMantenimiento.find(
                                                                (precio) =>
                                                                  precio.espacio ==
                                                                    "Mausoleo" &&
                                                                  precio.ano ==
                                                                    pagorporrealizar.anoPago
                                                              );

                                                            let resultado =
                                                              Object.assign(
                                                                {},
                                                                pagorporrealizar,
                                                                {
                                                                  tipo: "capilla",
                                                                }
                                                              );

                                                            setCrudAction({
                                                              action:
                                                                "abrirPago",
                                                              data: resultado,
                                                              precioMantenimiento:
                                                                buscandoPrecioYEspacio,
                                                              datosContrato:
                                                                finalDataDetalle,
                                                              preciosMantenimiento:
                                                                finalDataPreciosMantenimiento,
                                                            });
                                                          }}
                                                        >
                                                          Pagar
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </React.Fragment>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </>
                                      )}

                                      {/* Uniplex Si extisten las pinto*/}
                                      {Array.isArray(
                                        finalDataDetallePagos?.uniplex
                                      ) ? null : (
                                        <>
                                          <h6>Uniplex</h6>
                                          <table className="table table-sm">
                                            <thead>
                                              <tr>
                                                <th scope="col">Año</th>
                                                <th scope="col">Primer Año</th>
                                                <th scope="col">Estado</th>
                                                <th scope="col">
                                                  Monto a pagar
                                                </th>
                                                <th scope="col">
                                                  Monto pagado
                                                </th>
                                                <th scope="col">Acciones</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {finalDataDetallePagos?.uniplex?.Pagado?.map(
                                                (pagorealizado, index) => (
                                                  <React.Fragment key={index}>
                                                    <tr className="table-success">
                                                      <td>
                                                        {pagorealizado.anoPago}
                                                      </td>
                                                      <td>
                                                        {pagorealizado.primerAno ===
                                                        true ? (
                                                          <>Sí</>
                                                        ) : (
                                                          <>No</>
                                                        )}
                                                      </td>
                                                      <td>
                                                        {pagorealizado.estado}
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {pagorealizado.primerAno ===
                                                        true ? (
                                                          <>
                                                            {" "}
                                                            {
                                                              pagorealizado.precioPrimerAno
                                                            }
                                                          </>
                                                        ) : (
                                                          <>
                                                            {" "}
                                                            {
                                                              pagorealizado.precioLista
                                                            }
                                                          </>
                                                        )}
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {
                                                          pagorealizado.montoPagado
                                                        }
                                                      </td>
                                                      <td>
                                                        {pagorealizado.estado ===
                                                        "Pago parcial" ? (
                                                          <div
                                                            className="btn btn-sm btn-warning"
                                                            onClick={() => {
                                                              let holis =
                                                                finalDataPreciosMantenimiento;

                                                              let buscandoPrecioYEspacio =
                                                                finalDataPreciosMantenimiento.find(
                                                                  (precio) =>
                                                                    precio.espacio ==
                                                                      "Uniplex" &&
                                                                    precio.ano ==
                                                                      pagorealizado.anoPago
                                                                );

                                                              let resultado =
                                                                Object.assign(
                                                                  {},
                                                                  pagorealizado,
                                                                  {
                                                                    tipo: "capilla",
                                                                  }
                                                                );

                                                              setCrudAction({
                                                                action:
                                                                  "abrirPago",
                                                                data: resultado,
                                                                precioMantenimiento:
                                                                  buscandoPrecioYEspacio,
                                                                datosContrato:
                                                                  finalDataDetalle,
                                                                preciosMantenimiento:
                                                                  finalDataPreciosMantenimiento,
                                                              });
                                                            }}
                                                          >
                                                            Pagar
                                                          </div>
                                                        ) : null}
                                                      </td>
                                                    </tr>
                                                  </React.Fragment>
                                                )
                                              )}
                                            </tbody>
                                            <tbody>
                                              {finalDataDetallePagos?.uniplex?.PorPagar?.map(
                                                (pagorporrealizar, index) => (
                                                  <React.Fragment key={index}>
                                                    <tr className="table-warning">
                                                      <td>
                                                        {
                                                          pagorporrealizar.anoPago
                                                        }
                                                      </td>
                                                      <td>
                                                        {pagorporrealizar.primerAno ===
                                                        true ? (
                                                          <>Sí</>
                                                        ) : (
                                                          <>No</>
                                                        )}
                                                      </td>
                                                      <td>
                                                        {
                                                          pagorporrealizar.estado
                                                        }
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {pagorporrealizar.primerAno ===
                                                        true ? (
                                                          <>
                                                            {" "}
                                                            {
                                                              pagorporrealizar.precioPrimerAno
                                                            }
                                                          </>
                                                        ) : (
                                                          <>
                                                            {" "}
                                                            {
                                                              pagorporrealizar.precioLista
                                                            }
                                                          </>
                                                        )}
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {
                                                          pagorporrealizar.montoPagado
                                                        }
                                                      </td>
                                                      <td>
                                                        <div
                                                          className="btn btn-sm btn-warning"
                                                          onClick={() => {
                                                            let holis =
                                                              finalDataPreciosMantenimiento;

                                                            let buscandoPrecioYEspacio =
                                                              finalDataPreciosMantenimiento.find(
                                                                (precio) =>
                                                                  precio.espacio ==
                                                                    "Uniplex" &&
                                                                  precio.ano ==
                                                                    pagorporrealizar.anoPago
                                                              );

                                                            let resultado =
                                                              Object.assign(
                                                                {},
                                                                pagorporrealizar,
                                                                {
                                                                  tipo: "capilla",
                                                                }
                                                              );

                                                            setCrudAction({
                                                              action:
                                                                "abrirPago",
                                                              data: resultado,
                                                              precioMantenimiento:
                                                                buscandoPrecioYEspacio,
                                                              datosContrato:
                                                                finalDataDetalle,
                                                              preciosMantenimiento:
                                                                finalDataPreciosMantenimiento,
                                                            });
                                                          }}
                                                        >
                                                          Pagar
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </React.Fragment>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </>
                                      )}

                                      {/* Duplex Si extisten las pinto*/}
                                      {Array.isArray(
                                        finalDataDetallePagos?.duplex
                                      ) ? null : (
                                        <>
                                          <h6>Duplex</h6>
                                          <table className="table table-sm">
                                            <thead>
                                              <tr>
                                                <th scope="col">Año</th>
                                                <th scope="col">Primer Año</th>
                                                <th scope="col">Estado</th>
                                                <th scope="col">
                                                  Monto a pagar
                                                </th>
                                                <th scope="col">
                                                  Monto pagado
                                                </th>
                                                <th scope="col">Acciones</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {finalDataDetallePagos?.duplex?.Pagado?.map(
                                                (pagorealizado, index) => (
                                                  <React.Fragment key={index}>
                                                    <tr className="table-success">
                                                      <td>
                                                        {pagorealizado.anoPago}
                                                      </td>
                                                      <td>
                                                        {pagorealizado.primerAno ===
                                                        true ? (
                                                          <>Sí</>
                                                        ) : (
                                                          <>No</>
                                                        )}
                                                      </td>
                                                      <td>
                                                        {pagorealizado.estado}
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {pagorealizado.primerAno ===
                                                        true ? (
                                                          <>
                                                            {" "}
                                                            {
                                                              pagorealizado.precioPrimerAno
                                                            }
                                                          </>
                                                        ) : (
                                                          <>
                                                            {" "}
                                                            {
                                                              pagorealizado.precioLista
                                                            }
                                                          </>
                                                        )}
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {
                                                          pagorealizado.montoPagado
                                                        }
                                                      </td>
                                                      <td>
                                                        {pagorealizado.estado ===
                                                        "Pago parcial" ? (
                                                          <div
                                                            className="btn btn-sm btn-warning"
                                                            onClick={() => {
                                                              let holis =
                                                                finalDataPreciosMantenimiento;

                                                              let buscandoPrecioYEspacio =
                                                                finalDataPreciosMantenimiento.find(
                                                                  (precio) =>
                                                                    precio.espacio ==
                                                                      "Duplex" &&
                                                                    precio.ano ==
                                                                      pagorealizado.anoPago
                                                                );

                                                              let resultado =
                                                                Object.assign(
                                                                  {},
                                                                  pagorealizado,
                                                                  {
                                                                    tipo: "capilla",
                                                                  }
                                                                );

                                                              setCrudAction({
                                                                action:
                                                                  "abrirPago",
                                                                data: resultado,
                                                                precioMantenimiento:
                                                                  buscandoPrecioYEspacio,
                                                                datosContrato:
                                                                  finalDataDetalle,
                                                                preciosMantenimiento:
                                                                  finalDataPreciosMantenimiento,
                                                              });
                                                            }}
                                                          >
                                                            Pagar
                                                          </div>
                                                        ) : null}
                                                      </td>
                                                    </tr>
                                                  </React.Fragment>
                                                )
                                              )}
                                            </tbody>
                                            <tbody>
                                              {finalDataDetallePagos?.duplex?.PorPagar?.map(
                                                (pagorporrealizar, index) => (
                                                  <React.Fragment key={index}>
                                                    <tr className="table-warning">
                                                      <td>
                                                        {
                                                          pagorporrealizar.anoPago
                                                        }
                                                      </td>
                                                      <td>
                                                        {pagorporrealizar.primerAno ===
                                                        true ? (
                                                          <>Sí</>
                                                        ) : (
                                                          <>No</>
                                                        )}
                                                      </td>
                                                      <td>
                                                        {
                                                          pagorporrealizar.estado
                                                        }
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {pagorporrealizar.primerAno ===
                                                        true ? (
                                                          <>
                                                            {" "}
                                                            {
                                                              pagorporrealizar.precioPrimerAno
                                                            }
                                                          </>
                                                        ) : (
                                                          <>
                                                            {" "}
                                                            {
                                                              pagorporrealizar.precioLista
                                                            }
                                                          </>
                                                        )}
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {
                                                          pagorporrealizar.montoPagado
                                                        }
                                                      </td>
                                                      <td>
                                                        <div
                                                          className="btn btn-sm btn-warning"
                                                          onClick={() => {
                                                            let holis =
                                                              finalDataPreciosMantenimiento;

                                                            let buscandoPrecioYEspacio =
                                                              finalDataPreciosMantenimiento.find(
                                                                (precio) =>
                                                                  precio.espacio ==
                                                                    "Duplex" &&
                                                                  precio.ano ==
                                                                    pagorporrealizar.anoPago
                                                              );

                                                            let resultado =
                                                              Object.assign(
                                                                {},
                                                                pagorporrealizar,
                                                                {
                                                                  tipo: "capilla",
                                                                }
                                                              );

                                                            setCrudAction({
                                                              action:
                                                                "abrirPago",
                                                              data: resultado,
                                                              precioMantenimiento:
                                                                buscandoPrecioYEspacio,
                                                              datosContrato:
                                                                finalDataDetalle,
                                                              preciosMantenimiento:
                                                                finalDataPreciosMantenimiento,
                                                            });
                                                          }}
                                                        >
                                                          Pagar
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </React.Fragment>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </>
                                      )}

                                      {/* Murales Si extisten las pinto*/}
                                      {Array.isArray(
                                        finalDataDetallePagos?.murales
                                      ) ? null : (
                                        <>
                                          <h6>Murales</h6>
                                          <table className="table table-sm">
                                            <thead>
                                              <tr>
                                                <th scope="col">Año</th>
                                                <th scope="col">Primer Año</th>
                                                <th scope="col">Estado</th>
                                                <th scope="col">
                                                  Monto a pagar
                                                </th>
                                                <th scope="col">
                                                  Monto pagado
                                                </th>
                                                <th scope="col">Acciones</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {finalDataDetallePagos?.murales?.Pagado?.map(
                                                (pagorealizado, index) => (
                                                  <React.Fragment key={index}>
                                                    <tr className="table-success">
                                                      <td>
                                                        {pagorealizado.anoPago}
                                                      </td>
                                                      <td>
                                                        {pagorealizado.primerAno ===
                                                        true ? (
                                                          <>Sí</>
                                                        ) : (
                                                          <>No</>
                                                        )}
                                                      </td>
                                                      <td>
                                                        {pagorealizado.estado}
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {pagorealizado.primerAno ===
                                                        true ? (
                                                          <>
                                                            {" "}
                                                            {
                                                              pagorealizado.precioPrimerAno
                                                            }
                                                          </>
                                                        ) : (
                                                          <>
                                                            {" "}
                                                            {
                                                              pagorealizado.precioLista
                                                            }
                                                          </>
                                                        )}
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {
                                                          pagorealizado.montoPagado
                                                        }
                                                      </td>
                                                      <td>
                                                        {pagorealizado.estado ===
                                                        "Pago parcial" ? (
                                                          <div
                                                            className="btn btn-sm btn-warning"
                                                            onClick={() => {
                                                              let buscandoPrecioYEspacio =
                                                                finalDataPreciosMantenimiento.find(
                                                                  (precio) =>
                                                                    precio.espacio ==
                                                                      "Murales" &&
                                                                    precio.ano ==
                                                                      pagorealizado.anoPago
                                                                );

                                                              let resultado =
                                                                Object.assign(
                                                                  {},
                                                                  pagorealizado,
                                                                  {
                                                                    tipo: "capilla",
                                                                  }
                                                                );

                                                              setCrudAction({
                                                                action:
                                                                  "abrirPago",
                                                                data: resultado,
                                                                precioMantenimiento:
                                                                  buscandoPrecioYEspacio,
                                                                datosContrato:
                                                                  finalDataDetalle,
                                                                preciosMantenimiento:
                                                                  finalDataPreciosMantenimiento,
                                                              });
                                                            }}
                                                          >
                                                            Pagar
                                                          </div>
                                                        ) : null}
                                                      </td>
                                                    </tr>
                                                  </React.Fragment>
                                                )
                                              )}
                                            </tbody>
                                            <tbody>
                                              {finalDataDetallePagos?.murales?.PorPagar?.map(
                                                (pagorporrealizar, index) => (
                                                  <React.Fragment key={index}>
                                                    <tr className="table-warning">
                                                      <td>
                                                        {
                                                          pagorporrealizar.anoPago
                                                        }
                                                      </td>
                                                      <td>
                                                        {pagorporrealizar.primerAno ===
                                                        true ? (
                                                          <>Sí</>
                                                        ) : (
                                                          <>No</>
                                                        )}
                                                      </td>
                                                      <td>
                                                        {
                                                          pagorporrealizar.estado
                                                        }
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {pagorporrealizar.primerAno ===
                                                        true ? (
                                                          <>
                                                            {" "}
                                                            {
                                                              pagorporrealizar.precioPrimerAno
                                                            }
                                                          </>
                                                        ) : (
                                                          <>
                                                            {" "}
                                                            {
                                                              pagorporrealizar.precioLista
                                                            }
                                                          </>
                                                        )}
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {
                                                          pagorporrealizar.montoPagado
                                                        }
                                                      </td>
                                                      <td>
                                                        <div
                                                          className="btn btn-sm btn-warning"
                                                          onClick={() => {
                                                            let holis =
                                                              finalDataPreciosMantenimiento;

                                                            let buscandoPrecioYEspacio =
                                                              finalDataPreciosMantenimiento.find(
                                                                (precio) =>
                                                                  precio.espacio ==
                                                                    "Murales" &&
                                                                  precio.ano ==
                                                                    pagorporrealizar.anoPago
                                                              );

                                                            let resultado =
                                                              Object.assign(
                                                                {},
                                                                pagorporrealizar,
                                                                {
                                                                  tipo: "capilla",
                                                                }
                                                              );

                                                            setCrudAction({
                                                              action:
                                                                "abrirPago",
                                                              data: resultado,
                                                              precioMantenimiento:
                                                                buscandoPrecioYEspacio,
                                                              datosContrato:
                                                                finalDataDetalle,
                                                              preciosMantenimiento:
                                                                finalDataPreciosMantenimiento,
                                                            });
                                                          }}
                                                        >
                                                          Pagar
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </React.Fragment>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </React.Fragment>
                    </TabPanel>
                    <TabPanel value="2">
                      <EstadoDeCuentaPagosMantenimientoComponent
                        estadoDeCuenta={estadoDeCuenta}
                        finalDataDetalle={finalDataDetalle}
                      />
                    </TabPanel>
                  </TabContext>
                </Box>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className="container-fluid mt-3">
          <div className="row row-cols-md-4">
            {finalReportePorEspacio.map((espacio, index) => (
              <React.Fragment key={index}>
                <div className="col">
                  <div
                    className="card mb-2 cursor-pointer"
                    onClick={() => {
                      setNumeroContratoEncontrado(espacio);
                    }}
                  >
                    <div className="card-body d-flex justify-content-between d-flex align-items-center">
                      <div>
                        <strong>
                          {" "}
                          {espacio.serie} - {espacio.noContrato}{" "}
                        </strong>
                        <br />
                        {espacio.titular} <br /> {espacio.resumen} <br />
                      </div>
                      <Box
                        sx={{ position: "relative", display: "inline-flex" }}
                      >
                        <CircularProgress
                          variant="determinate"
                          value={espacio.porcentage}
                        />
                        <Box
                          sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                          >
                            {`${espacio.porcentage}%`}
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      )}

      {crudAction.action == "abrirPago" ? (
        <>
          <AddPaymentMantenimientoComponentCrudSegundaVersion
            crudAction={crudAction}
            setCrudAction={setCrudAction}
          />
        </>
      ) : null}
    </React.Fragment>
  );
};
