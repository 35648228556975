import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import { obtenerEspaciosEnCapillaByIdCapilla } from "../../API/ApiCapillas";
import { AuthContext } from "../../auth/authContext";
import { AddEspaciosACapillaComponent } from "../../Components/AddEspaciosACapillaComponent/AddEspaciosACapillaComponent";
import { AsignarFallecidoAEspacioEnCapillaComponent } from "../../Components/AsignarFallecidoAEspacioEnCapillaComponent/AsignarFallecidoAEspacioEnCapillaComponent";
import { LoadingSkeletonComponent } from "./../../Components/LoadingSkeletonComponent/LoadingSkeletonComponent";
import AddIcon from "@mui/icons-material/Add";
import { AsignarContratoAEspacioEnCapillaComponent } from "../../Components/AsignarContratoAEspacioEnCapillaComponent/AsignarContratoAEspacioEnCapillaComponent";
import CachedIcon from "@mui/icons-material/Cached";
import ErrorIcon from "@mui/icons-material/Error";
import { LoadingComponent } from "../../Components/LoadingComponent/LoadingComponent";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { AsignarFechaDeinicioDePagoaEspacioComponent } from "./../../Components/AsignarFechaDeinicioDePagoaEspacioComponent/AsignarFechaDeinicioDePagoaEspacioComponent";
const guidVista = "6b203d5f-1db7-4edf-a929-d8e21c15e9aa";

export const EspaciosDeCapillaComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(true);
  const [finalData, setFinalData] = React.useState({
    capilla: [],
    capillaPosicionEspacio: [],
  });

  let { idcapilla } = useParams();

  idcapilla = JSON.parse(idcapilla);

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  const [permisoAgregarEspaciosCapilla, setPermisoAgregarEspaciosCapilla] =
    React.useState(false);
  const [permisoAsignarContrato, setPermisoAsignarContrato] =
    React.useState(false);
  const [permisoAsignarPersona, setPermisoAsignarPersona] =
    React.useState(false);

  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "c69c08be-0d1a-4b40-b389-68f92f1efd37" &&
              permiso.allowed == 1
            ) {
              setPermisoAgregarEspaciosCapilla(true);
            }
            if (
              permiso.guidFuncion == "07786f1f-7244-415d-8f60-f7ce465f9c20" &&
              permiso.allowed == 1
            ) {
              setPermisoAsignarContrato(true);
            }
            if (
              permiso.guidFuncion == "bed5357d-3c73-4b16-b48c-2471c2dc8080" &&
              permiso.allowed == 1
            ) {
              setPermisoAsignarPersona(true);
            }
          }
        }
      }
    );

    return () => {
      ready = true;
    };
  }, []);

  React.useEffect(() => {
    setLoading(true);
    obtenerEspaciosEnCapillaByIdCapilla(user, idcapilla.id).then(
      (resultado) => {
        if (resultado.status == 200) {
          let espaciosTotales = resultado.data.capillaPosicionEspacio.length;
          let espaciosConContratoTotales = 0;
          let personasEspaciostotales = 0;

          
          for (const capilla of resultado.data.capillaPosicionEspacio) {
            if (capilla.idcontrato == null || capilla.idcontrato == 0) {
            } else {
              espaciosConContratoTotales = espaciosConContratoTotales + 1;
            }

            for (const muerto of capilla.espacios) {
              if (muerto.fechaIn === "0000-00-00" || muerto.persona === " ") {
              } else {
                personasEspaciostotales = personasEspaciostotales + 1;
              }
            }
          }
          
          resultado.data.capilla.espaciosTotales = espaciosTotales;
          resultado.data.capilla.espaciosConContratoTotales =
            espaciosConContratoTotales;
          resultado.data.capilla.personasEspaciostotales =
            personasEspaciostotales;

          setFinalData(resultado.data);
        } else {
          setFinalData([]);
        }
        setLoading(false);
      }
    );
  }, [crudAction]);

  return (
    <>
      {permisoAgregarEspaciosCapilla == true ? (
        <>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 text-end my-2">
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => {
                    setCrudAction({
                      action: "add",
                      data: null,
                    });
                  }}
                >
                  Agregar Espacios a {idcapilla.nombre}
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="container-fluid">
                  <div className="row row-cols-md-1">
                    <div className="col text-center">
                      <h4 className="m-0 p-0">
                        {finalData.capilla[0]?.nombreCapilla}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="container-fluid">
                  <div className="row row-cols-md-4">
                    <div className="col text-center">
                      <h6>Espacios Creados</h6>
                      <strong> {finalData.capilla.espaciosTotales}</strong>
                    </div>
                    <div className="col text-center">
                      <strong>
                        <h6>Espacios Con Contrato asignado</h6>
                        {finalData.capilla.espaciosConContratoTotales}
                      </strong>
                    </div>
                    <div className="col text-center">
                      <strong>
                        <h6>Espacios Sin Contrato</h6>
                        {finalData.capilla.espaciosTotales -
                          finalData.capilla.espaciosConContratoTotales}
                      </strong>
                    </div>
                    <div className="col text-center">
                      <strong>
                        <h6>Fallecidos en espacio</h6>
                        {finalData.capilla.personasEspaciostotales}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row row-cols-md-3 mt-3">
          {finalData.capillaPosicionEspacio.map((posicion, index) => (
            <div key={index} className="col mb-2">
              <div className="card">
                <div className="card-header d-flex justify-content-between d-flex align-items-center">
                  <span>
                    Posición <strong>{posicion.noPosicion}</strong>
                  </span>
                  <strong>
                    {posicion.contrato == null ? (
                      <>
                        <ErrorIcon /> Sin Contrato
                      </>
                    ) : (
                      <a
                        href={`/contrato/${posicion.idcontrato}/false`}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <i>
                          {posicion.contrato == null
                            ? "Sin Contrato"
                            : posicion.contrato}
                        </i>
                      </a>
                    )}
                  </strong>
                  {permisoAsignarContrato == true ? (
                    <div>
                      <button
                        className="btn btn-sm btn-link"
                        onClick={() => {
                          setCrudAction({
                            action: "asignarContrato",
                            data: posicion,
                            posicion: posicion,
                          });
                        }}
                      >
                        <AddIcon /> Contrato
                      </button>
                      <button
                        className="btn btn-sm btn-link"
                        onClick={() => {
                          setCrudAction({
                            action: "actualizarFecha",
                            data: posicion,
                            posicion: posicion,
                          });
                        }}
                      >
                        <CachedIcon /> Fecha
                      </button>
                    </div>
                  ) : null}
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <small>
                        {" "}
                        Fecha asignación:{" "}
                        {posicion.fechaAsignacion == null
                          ? "Sin Fecha"
                          : posicion.fechaAsignacion}
                      </small>
                    </li>
                  </ul>
                  <ul className="list-group list-group-flush">
                    {posicion.espacios.map((lugar, index) => (
                      <li
                        key={index}
                        className="list-group-item d-flex justify-content-between align-items-start"
                      >
                        <span>
                          {lugar.persona == null ? "-" : lugar.persona}

                          {lugar.fechaEx == null ? null : (
                            <>
                              <br />
                              <small>Exhumación:</small> {lugar.fechaEx}
                            </>
                          )}

                          {lugar.fechaIn == null ? null : (
                            <>
                              <br />
                              <small>Inhumación:</small> {lugar.fechaIn}
                            </>
                          )}
                        </span>

                        {permisoAsignarPersona == true ? (
                          <>
                            {posicion.idcontrato == null ? (
                              <>
                                {permisoAsignarContrato == true ? (
                                  <button
                                    className="btn btn-sm btn-link"
                                    onClick={() => {
                                      setCrudAction({
                                        action: "asignarContrato",
                                        data: posicion,
                                        posicion: posicion,
                                      });
                                    }}
                                  >
                                    <AddIcon /> Contrato
                                  </button>
                                ) : null}
                              </>
                            ) : (
                              <>
                                {lugar.persona == null ? (
                                  <button
                                    className="btn btn-sm btn-outline-success"
                                    onClick={() => {
                                      setCrudAction({
                                        action: "asignFallecido",
                                        data: lugar,
                                        posicion: posicion,
                                      });
                                    }}
                                  >
                                    Asignar
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-sm btn-outline-warning"
                                    onClick={() => {
                                      setCrudAction({
                                        action: "asignFallecido",
                                        data: lugar,
                                        posicion: posicion,
                                      });
                                    }}
                                  >
                                    Actualizar
                                  </button>
                                )}
                              </>
                            )}
                          </>
                        ) : null}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {loading == true ? <LoadingComponent /> : null}

      {crudAction.action == "add" ? (
        <>
          <AddEspaciosACapillaComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            capilla={idcapilla}
          />
        </>
      ) : null}
      {crudAction.action == "asignFallecido" ? (
        <>
          <AsignarFallecidoAEspacioEnCapillaComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            capilla={idcapilla}
          />
        </>
      ) : null}
      {crudAction.action == "asignarContrato" ? (
        <>
          <AsignarContratoAEspacioEnCapillaComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            capilla={idcapilla}
          />
        </>
      ) : null}

      {crudAction.action == "actualizarFecha" ? (
        <>
          <AsignarFechaDeinicioDePagoaEspacioComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            idEspacio={1}
          />
        </>
      ) : null}
    </>
  );
};
