import React from "react";
import { BusquedaDePagosPorContratoComponent } from "../../Components/BusquedaDePagosPorContratoComponent/BusquedaDePagosPorContratoComponent";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { ButtonDownloadToXlsComponent } from "../../Components/ButtonDownloadToXlsComponent/ButtonDownloadToXlsComponent";

export const BusquedaDePagosScreenComponent = () => {
  const [finalData, setFinalData] = React.useState([]);

  const [filtroSeleccionado, setFiltroSeleccionado] = React.useState("All");

  React.useEffect(() => {}, [filtroSeleccionado]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mt-3 mb-3">
            <BusquedaDePagosPorContratoComponent
              finalData={finalData}
              setFinalData={setFinalData}
              filtroSeleccionado={filtroSeleccionado}
              setFiltroSeleccionado={setFiltroSeleccionado}
            />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row row-cols-md-1">
          <div className="col" style={{ textAlign: "right" }}>
            <ButtonDownloadToXlsComponent
              data={finalData}
              fileName="Buscador de recibod"
            />
          </div>
        </div>
                  
      </div>

      {filtroSeleccionado == "All" ? (
        <React.Fragment>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 px-2">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Número de Recibo</th>
                      <th scope="col">Contrato</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {finalData.map((pago, index) => (
                      <tr key={index}>
                        <th scope="row">
                          {pago.serie} - {pago.folio}
                        </th>
                        <td>
                          <a href={`contrato/${pago.idcontrato}/false`}>
                            Contrato: {pago.noContrato}
                          </a>
                        </td>
                        <td>{pago.fechaPago}</td>
                        <td>
                          {" "}
                          {"$ "}
                          {parseFloat(pago.monto)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 px-2">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Número de Recibo</th>
                      <th scope="col">Contrato</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {finalData.map((pago, index) => (
                      <React.Fragment key={index}>
                        {pago.serie == filtroSeleccionado ? (
                          <React.Fragment>
                            <tr key={index}>
                              <th scope="row">
                                {pago.serie} - {pago.folio}
                              </th>
                              <td>
                                <a href={`contrato/${pago.idcontrato}/false`}>
                                  Contrato: {pago.noContrato}
                                </a>
                              </td>
                              <td>{pago.fechaPago}</td>
                              <td>
                                {" "}
                                {"$ "}
                                {parseFloat(pago.monto)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </td>
                            </tr>
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </>
  );
};
