import { API } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";
import { RepeatOneSharp } from "@mui/icons-material";
import { DateTime } from "luxon";


export const reporteAuditoria = async (user, data) => {
  if (data.fechaInicio == "") {
    data.fechaInicio = null;
  }
  if (data.fechaFin == "") {
    data.fechaFin = null;
  }

  let url =
    API +
    `reports/reporteAuditoria/?fechaInicial=${data.fechaInicio}&fechaFinal=${data.fechaFin}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {


        for (const auditoria of response.data) {

            auditoria.createdAt = DateTime.fromISO(auditoria.createdAt).toFormat('F');
            
        }

      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
