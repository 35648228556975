import { API } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";
export const createUser = async (user, values) => {
  let url = API + "users/newUser";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const changePasswordUser = async (iduser, user, values) => {
  let url = API + "users/updatePassword/" + iduser;

  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const desactivarUser = async (iduser, user, values) => {


  
  let url = "";
  if (values.status == 1) {
    url = API + "users/deactivateUser/" + iduser;
  } else {
    url = API + "users/activateUser/" + iduser;
  }


  try {
    const response = await axios.put(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getUsers = async (user) => {
  let url = API + "users/getUsers";

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
