import React from "react";
import {
  Document,
  View,
  Page,
  Text,
  PDFDownloadLink,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import logoVitanova from "../../Images/LogotipoNegro.png";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  image: {
    width: "30%",
    height: "auto",
    textAlign: "center",
    alignSelf: "center",
  },
  titulo: {
    fontSize: 12,
    marginTop: 30,
    textAlign: "center",
  },
  tituloNombre: {
    fontSize: 12,
    marginTop: 10,
    textAlign: "center",
  },
  firmaConformidad: {
    fontSize: 10,
    marginTop: 200,
    textAlign: "center",
  },
  textoFirmaConformidad: {
    fontSize: 10,
    textAlign: "center",
  },
});

export const ImprimirPdfComponentOrdenesServicio = (props) => {
  const generatePDF = () => (
    <>
      <Document>
        {props.data.map((recibo) => (
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <Image style={styles.image} src={logoVitanova} />
              <Text style={styles.titulo}>
                Recibo de comisiones del día: {props.fechaInicio} al{" "}
                {props.fechaFin}
              </Text>
              <Text style={styles.tituloNombre}>
                Nombre: {recibo.nombreEmpleado}
              </Text>

              <Text style={styles.tituloNombre}>
                Total de Comisiones: $ {recibo.totalComision}
              </Text>

              <Text style={styles.firmaConformidad}>
                ________________________________________________________________________________
              </Text>

              <Text style={styles.textoFirmaConformidad}>
                Recibí la cantidad de: $ {recibo.totalComision} por concepto de:
              </Text>
              <Text style={styles.textoFirmaConformidad}>
                comisiones de ordenes de servicio
              </Text>
              <Text style={styles.textoFirmaConformidad}>
                del día: {props.fechaInicio} al {props.fechaFin}
              </Text>
            </View>
          </Page>
        ))}
      </Document>
    </>
  );

  return (
    <div>
      <PDFDownloadLink
        document={generatePDF()}
        fileName={`Comisiones_OS_${props.fechaInicio}_${props.fechaFin}.pdf`}
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            "Cargando documento..."
          ) : (
            <div className="btn btn-sm btn-danger mt-3">Descargar PDF</div>
          )
        }
      </PDFDownloadLink>
    </div>
  );
};
