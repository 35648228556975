import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import { DateTime } from "luxon";
import {
  createNewOrder,
  editOrdenServicio,
} from "../../API/ApiOrdenesServicio";
import InputAdornment from "@mui/material/InputAdornment";
import { FactCheckIcon } from "@mui/icons-material/FactCheck";

export const EditarSolicitanteOrdenServicioComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);
  let arr = props.finalData.folio.split("");
  let numeroFolio = props.finalData.folio;

  const formik = useFormik({
    initialValues: {
      letraFolio: arr,
      noContrato: props.finalData.noContrato,
      folio: props.finalData.folio,
      fecha: props.finalData.fecha,
      nombreFinado: props.finalData.nombreFinado,
      lugarNacimiento: props.finalData.lugarNacimiento,
      fechaNacimiento: props.finalData.fechaNacimiento,
      sexo: props.finalData.sexo,
      nacionalidad: props.finalData.nacionalidad,
      estadoCivil: props.finalData.estadoCivil,
      domicilio: props.finalData.domicilio,
      colonia: props.finalData.colonia,
      derechohabiente: props.finalData.derechohabiente,
      ocupacion: props.finalData.ocupacion,
      nombrePadre: props.finalData.nombrePadre,
      nombreMadre: props.finalData.nombreMadre,
      nombreConyugue: props.finalData.nombreConyugue,
      lugarDefuncion: props.finalData.lugarDefuncion,
      fechaDefuncion: props.finalData.fechaDefuncion,
      horaDefuncion: props.finalData.horaDefuncion,
      atencionMedica: props.finalData.atencionMedica,
      lugarVelacion: props.finalData.lugarVelacion,
      horaMisa: props.finalData.horaMisa,
      templo: props.finalData.templo,
      horaSepelio: props.finalData.horaSepelio,
      ubicacion: props.finalData.ubicacion,
      perpetuidad: props.finalData.perpetuidad,
      nomAexhum: props.finalData.nomAexhum,
      FDPAExhum: props.finalData.FDPAExhum,
      perpetuidadAExhum: props.finalData.perpetuidadAExhum,
      nombreSolicitante: props.finalData.nombreSolicitante,
      domicilioSolicitante: props.finalData.domicilioSolicitante,
      coloniaSolicitante: props.finalData.coloniaSolicitante,
      parentesco: props.finalData.parentesco,
      telefonoSolicitante: props.finalData.telefonoSolicitante,
      emergencia: props.finalData.emergencia,
      fechaLPago: props.finalData.fechaLPago,
      nombrePanteon: props.finalData.nombrePanteon,
      observaciones: props.finalData.observaciones,
      clasificacion: props.finalData.clasificacion,
      horaCremacion: props.finalData.horaCremacion,
      hornoCremacion: props.finalData.hornoCremacion,
      fechaCremacion: props.finalData.fechaCremacion,
      fechaExhumar: props.finalData.fechaExhumar,
      nombreExhumar: props.finalData.nombreExhumar,
      horaExhumar: props.finalData.horaExhumar,
      comentarioExhumar: props.finalData.comentarioExhumar,
    },

    onSubmit: (values) => {
      setIsDisabled(true);

    
      values.fechaLPago = DateTime.now().toISODate();

      editOrdenServicio(user, values).then((resultado) => {
        if (resultado.status == 200) {
          toast.success(
            "Se ha agregado la orden de servicio, consulta pestaña de detalle"
          );
          setIsDisabled(false);
        }
      });
    },
  });

  return (
    <>
      <div className="container-fluid mt-2">
        <div className="row">
          <div className="col-12">
            {isDisabled == true ? (
              <>
                <Alert className="mb-3" severity="info">
                  Procesando información...
                </Alert>
              </>
            ) : null}
          </div>
        </div>
        <div className="row">
          <form onSubmit={formik.handleSubmit} className="container-fluid">
            <div className="row">
              <div className="col mb-3 fw-bold h5">Datos de Solicitante</div>
            </div>
            <div className="row row-cols-3">
              <div className="col d-none">
                <TextField
                  size="small"
                  fullWidth
                  id="nombrePadre"
                  name="nombrePadre"
                  label="Nombre Padre"
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.nombrePadre}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.nombrePadre &&
                    Boolean(formik.errors.nombrePadre)
                  }
                  helperText={
                    formik.touched.nombrePadre && formik.errors.nombrePadre
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col d-none">
                <TextField
                  size="small"
                  fullWidth
                  id="nombreMadre"
                  name="nombreMadre"
                  label="Nombre Madre"
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.nombreMadre}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.nombreMadre &&
                    Boolean(formik.errors.nombreMadre)
                  }
                  helperText={
                    formik.touched.nombreMadre && formik.errors.nombreMadre
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col d-none">
                <TextField
                  size="small"
                  fullWidth
                  id="nombreConyugue"
                  name="nombreConyugue"
                  label="Nombre Conyuge"
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.nombreConyugue}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.nombreConyugue &&
                    Boolean(formik.errors.nombreConyugue)
                  }
                  helperText={
                    formik.touched.nombreConyugue &&
                    formik.errors.nombreConyugue
                  }
                  disabled={isDisabled}
                />
              </div>
            </div>
            <div className="row row-cols-md-2">
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="nombreSolicitante"
                  name="nombreSolicitante"
                  label="Nombre Solicitante"
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.nombreSolicitante}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.nombreSolicitante &&
                    Boolean(formik.errors.nombreSolicitante)
                  }
                  helperText={
                    formik.touched.nombreSolicitante &&
                    formik.errors.nombreSolicitante
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="parentesco"
                  name="parentesco"
                  label="Parentesco"
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.parentesco}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.parentesco &&
                    Boolean(formik.errors.parentesco)
                  }
                  helperText={
                    formik.touched.parentesco && formik.errors.parentesco
                  }
                  disabled={isDisabled}
                />
              </div>
            </div>
            <div className="row row-cols-md-2">
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="domicilioSolicitante"
                  name="domicilioSolicitante"
                  label="Domicilio Solicitante"
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.domicilioSolicitante}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.domicilioSolicitante &&
                    Boolean(formik.errors.domicilioSolicitante)
                  }
                  helperText={
                    formik.touched.domicilioSolicitante &&
                    formik.errors.domicilioSolicitante
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="coloniaSolicitante"
                  name="coloniaSolicitante"
                  label="Colonia"
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.coloniaSolicitante}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.coloniaSolicitante &&
                    Boolean(formik.errors.coloniaSolicitante)
                  }
                  helperText={
                    formik.touched.coloniaSolicitante &&
                    formik.errors.coloniaSolicitante
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="telefonoSolicitante"
                  name="telefonoSolicitante"
                  label="Telefono Solicitante"
                  type={"number"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.telefonoSolicitante}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.telefonoSolicitante &&
                    Boolean(formik.errors.telefonoSolicitante)
                  }
                  helperText={
                    formik.touched.telefonoSolicitante &&
                    formik.errors.telefonoSolicitante
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="emergencia"
                  name="emergencia"
                  label="Telefono Emergencia"
                  type={"number"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.emergencia}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.emergencia &&
                    Boolean(formik.errors.emergencia)
                  }
                  helperText={
                    formik.touched.emergencia && formik.errors.emergencia
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="observaciones"
                  name="observaciones"
                  label="Observaciones"
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.observaciones}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.observaciones &&
                    Boolean(formik.errors.observaciones)
                  }
                  helperText={
                    formik.touched.observaciones && formik.errors.observaciones
                  }
                  disabled={isDisabled}
                />
              </div>

              <div className="col">
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Clasificación
                  </InputLabel>
                  <NativeSelect
                    value={formik.values.clasificacion}
                    onChange={formik.handleChange}
                    disabled={isDisabled}
                    inputProps={{
                      name: "clasificacion",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={"P"}>Pagado</option>
                    <option value={"C"}>Crédito</option>
                  </NativeSelect>
                </FormControl>
              </div>
            </div>

            {props.permisoParaEditar == true ? (
              <>
                {" "}
                <div className="row row-cols-1 mt-3">
                  <div className="col">
                    <button
                      type="submit"
                      className="btn w-100 btn-sm btn-success"
                    >
                      Editar datos de Solicitante
                    </button>
                  </div>
                </div>
              </>
            ) : null}
          </form>
        </div>
      </div>
    </>
  );
};
