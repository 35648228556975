import React from "react";
import styles from "./EditarPagoComponent.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import {
  editContractPayment,
  newContractPayment,
} from "../../API/ApiPagosContratos";

const validationSchema = yup.object({
  serie: yup.string("Ingresa serie").required("Serie requerido"),
  folio: yup.string("Ingresa serie").required("Serie requerido"),
  noContrato: yup
    .string("Ingresa Número de contrato")
    .required("Número de contrato requerido"),
  monto: yup.string("Ingresa monto").required("Monto requerido"),
  fechaPago: yup.string("Ingresa fecha de pago").required("Fecha requerida"),
});

const getDateTime = () => {
  const today = new Date();
  const numberOfDaysToAdd = 0;
  const date = today.setDate(today.getDate() + numberOfDaysToAdd);
  const defaultValue = new Date(date).toISOString().split("T")[0]; // yyyy-mm-dd
  const currDate = defaultValue;
  return currDate;
};

export const EditarPagoComponent = (props) => {
 

  const { user, dispatch } = React.useContext(AuthContext);
  const searchInput = React.useRef(null);

  const [isDisabled, setIsDisabled] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      serie: props.crudAction.data.serie,
      folio: props.crudAction.data.folio,
      noContrato: props.crudAction.data.noContrato,
      idcontrato: props.crudAction.data.idcontrato,
      monto: props.crudAction.data.monto,
      fechaPago: getDateTime(),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

      
      toast.info("Actualizando información de pago");

      setIsDisabled(true);

      editContractPayment(user, values, props.crudAction.data.id).then(
        (resultado) => {
          if (resultado.status == 200) {
            toast.success("Se ha editado correctamernte el pago");
            let contadorActualizar = props.contadorActualizar;

            window.location.reload();

            
            props.setContadorActualizar(contadorActualizar + 1);

            props.setCrudAction({ action: null, data: null });

            setIsDisabled(false);
          } else {
            toast.error("ha ocurrido un error");
            setIsDisabled(true);
          }
        }
      );
    },
  });

  const escFunction = (event) => {
    if (event.keyCode === 27 && isDisabled == false) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };

    searchInput.current.focus();
  }, []);

  return (
    <>
      <div className={styles.modalAgregarPagoContainer}>
        <div className="container">
          <form className="row" onSubmit={formik.handleSubmit}>
            <div className="col-md-7 col-sm-12 mt-md-5 bg-white mx-auto rounded-2">
              <div className="text-start p-2">
                <h5 className="m-0 p-0">Editar Pago</h5>
                <hr className="p-0 m-0 mt-2 mb-2" />
              </div>
              <div className="contenido p-2">
                {isDisabled == true ? (
                  <>
                    <Alert severity="info" className="my-3">
                      Estamos editando el pago... espera un momento
                    </Alert>
                  </>
                ) : null}
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6">
                      <TextField
                        size="small"
                        fullWidth
                        variant="standard"
                        className="mb-2"
                        id="noContrato"
                        disabled="true"
                        name="noContrato"
                        label="Número de Contrato:"
                        value={formik.values.noContrato}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.noContrato &&
                          Boolean(formik.errors.noContrato)
                        }
                        helperText={
                          formik.touched.noContrato && formik.errors.noContrato
                        }
                       
                      />
                    </div>
                    <div className="col-md-6">
                      <TextField
                        size="small"
                        ref={searchInput}
                        fullWidth
                        variant="standard"
                        className="mb-2"
                        id="fechaPago"
                        name="fechaPago"
                        type={"date"}
                        label="fechaPago: "
                        value={formik.values.fechaPago}
                        InputLabelProps={{ shrink: true }}
                        onChange={formik.handleChange}
                        disabled={isDisabled}
                        error={
                          formik.touched.fechaPago &&
                          Boolean(formik.errors.fechaPago)
                        }
                        helperText={
                          formik.touched.fechaPago && formik.errors.fechaPago
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6">
                      <FormControl fullWidth>
                        <InputLabel
                          variant="standard"
                          htmlFor="uncontrolled-native"
                        >
                          Serie
                        </InputLabel>
                        <NativeSelect
                          value={formik.values.serie}
                          onChange={formik.handleChange}
                          defaultValue={"C"}
                          disabled={isDisabled}
                          inputProps={{
                            name: "serie",
                            id: "serie",
                          }}
                        >
                          <option value={"A"}>A</option>
                          <option value={"AA"}>AA</option>
                          <option value={"B"}>B</option>
                          <option value={"BB"}>BB</option>
                          <option value={"D"}>D</option>
                          <option value={"E"}>E</option>
                          <option value={"F"}>F</option>
                          <option value={"G"}>G</option>
                          <option value={"H"}>H</option>
                          <option value={"I"}>I</option>
                          <option value={"J"}>J</option>
                          <option value={"MA"}>MA</option>
                          <option value={"SM"}>SM</option>
                        </NativeSelect>
                      </FormControl>
                    </div>
                    <div className="col-md-6">
                      <TextField
                        size="small"
                        fullWidth
                        className="mb-3"
                        id="folio"
                        name="folio"
                        disabled={isDisabled}
                        type={"number"}
                        variant="standard"
                        label="Folio:"
                        value={formik.values.folio}
                        InputProps={{ inputProps: { min: 0 } }}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.folio && Boolean(formik.errors.folio)
                        }
                        helperText={formik.touched.folio && formik.errors.folio}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <TextField
                        size="small"
                        fullWidth
                        className="mb-2"
                        id="monto"
                        name="monto"
                        disabled={isDisabled}
                        variant="standard"
                        type={"number"}
                        label="Monto: $"
                        value={formik.values.monto}
                        InputProps={{ inputProps: { min: 0 , step: 'any' } }}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.monto && Boolean(formik.errors.monto)
                        }
                        helperText={formik.touched.monto && formik.errors.monto}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="pie p-2">
                <hr />
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button
                    className="btn btn-sm btn-outline-danger rounded-4"
                    type="button"
                    disabled={isDisabled}
                    onClick={() => {
                      props.setCrudAction({ action: null, data: null });
                    }}
                  >
                    Cerrar
                  </button>
                  <button
                    className="btn btn-sm btn-success rounded-4"
                    type="submit"
                    disabled={isDisabled}
                  >
                    Editar Pago
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
