import React from "react";
import { createBrowserHistory } from "history";
import { AuthContext } from "../../auth/authContext";
import AddIcon from "@mui/icons-material/Add";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { ContratosEtapaUnoComponent } from "../../Components/ContratosEtapaUnoComponent/ContratosEtapaUnoComponent";
import { getLastContracts } from "../../API/ApiContratos";
import { CardContratoHeaderComponent } from "../../Components/CardContratoHeaderComponent/CardContratoHeaderComponent";
import { BuscadorDeContratosComponent } from "../../Components/BuscadorDeContratosComponent/BuscadorDeContratosComponent";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { SinPermisoComponent } from "../../Components/SinPermisoComponent/SinPermisoComponent";
import { ContratosRestructuraEtapaUnoComponent } from "../../Components/ContratosEtapaUnoComponent/ContratosRestructuraEtapaUnoComponent";
import { CancelarFolioPrevisionComponent } from "../../Components/CancelarFolioPrevisionComponent/CancelarFolioPrevisionComponent";
import RuleFolderIcon from "@mui/icons-material/RuleFolder";
import { ContratosRestructuraMultipleEtapaUnoComponent } from "../../Components/ContratosEtapaUnoComponent/ContratosRestructuraMultipleEtapaUnoComponent";
const guidVista = "6ac593c2-4810-4081-bb29-e80830750a85";

export const ContratosScreenComponent = () => {
  const customHistory = createBrowserHistory();
  const { user, dispatch } = React.useContext(AuthContext);
  const [isloadingData, setIsLoadingData] = React.useState(true);
  const [finalData, setFinalData] = React.useState([]);

  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  const [permisoParaAgregar, setPermisoParaAgregar] = React.useState(false);
  const [permisoParaVisualizar, setPermisoParaVisualizar] =
    React.useState(false);
  const [permisoParaEditar, setPermisoParaEditar] = React.useState(false);

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "8b3c77c9-f655-43ae-97b8-0ab8638586ce" &&
              permiso.allowed == 1
            ) {
              setPermisoParaAgregar(true);
            }
            if (
              permiso.guidFuncion == "acba4134-52e5-4f14-bb9d-7cdac5a94920" &&
              permiso.allowed == 1
            ) {
              setPermisoParaVisualizar(true);
            }
            if (
              permiso.guidFuncion == "704eae79-bee9-42ee-b27c-6e744aea8e65" &&
              permiso.allowed == 1
            ) {
              setPermisoParaEditar(true);
            }
          }
        }
      }
    );

    return () => {
      ready = true;
    };
  }, []);

  React.useEffect(() => {
    let listo = false;
    getLastContracts(user).then((resultado) => {
      if (resultado.status == 200) {
        setFinalData(resultado.data);
      } else {
        setFinalData([]);
      }
    });
    return () => {
      listo = true;
    };
  }, [crudAction]);

  return (
    <>
      {permisoParaVisualizar == true ? (
        <>
          {permisoParaAgregar == true ? (
            <>
              <div className="container-fluid text-end">
                <div className="row">
                  <div className="col-12">
                    <button
                      className="btn btn-dark btn-sm me-2"
                      onClick={() => {
                        setCrudAction({
                          action: "restructuraMultiplesContratos",
                          data: null,
                        });
                      }}
                    >
                      <AddIcon />
                      Restructura de múltiples contratos
                    </button>
                    <button
                      className="btn btn-dark btn-sm me-2 d-none"
                      onClick={() => {
                        setCrudAction({
                          action: "restructura",
                          data: null,
                        });
                      }}
                    >
                      <AddIcon />
                      Restructura de contrato
                    </button>
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() => {
                        setCrudAction({
                          action: "add",
                          data: null,
                        });
                      }}
                    >
                      <AddIcon />
                      Agregar Contrato de Previsión
                    </button>
                    <br />
                    <br />
                    <button
                      className="btn btn-warning btn-sm ms-1"
                      onClick={() => {
                        setCrudAction({
                          action: "cancelarFolioPrevision",
                          data: null,
                        });
                      }}
                    >
                      <RuleFolderIcon /> Cancelar Folio de Previsión
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          <div className="container-fluid">
            <div className="row my-5">
              <div className="col my-2">
                <BuscadorDeContratosComponent />
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row row-cols-md-3">
              <div className="col my-2">
                <h5>Últimos contratos creados</h5>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row row-cols-md-3">
              {finalData.map((contrato, index) => (
                <CardContratoHeaderComponent
                  contrato={contrato}
                  key={index}
                  permisoParaEditar={permisoParaEditar}
                />
              ))}
            </div>
          </div>

          {crudAction.action === "add" ? (
            <ContratosEtapaUnoComponent
              crudAction={crudAction}
              setCrudAction={setCrudAction}
              nombre="Contrato de Previsión Etapa 1"
            />
          ) : null}
          {crudAction.action === "restructura" ? (
            <ContratosRestructuraEtapaUnoComponent
              crudAction={crudAction}
              setCrudAction={setCrudAction}
              nombre="Nuevo contrato a partir de restructura"
            />
          ) : null}
          {crudAction.action === "restructuraMultiplesContratos" ? (
            <ContratosRestructuraMultipleEtapaUnoComponent
              crudAction={crudAction}
              setCrudAction={setCrudAction}
              nombre="Nuevo contrato a partir de restructura de múltiples contratos"
            />
          ) : null}
          {crudAction.action === "cancelarFolioPrevision" ? (
            <CancelarFolioPrevisionComponent
              crudAction={crudAction}
              setCrudAction={setCrudAction}
              nombre="Cancelar un folio de previsión"
            />
          ) : null}
        </>
      ) : (
        <SinPermisoComponent />
      )}
    </>
  );
};
