import React from "react";
import { BuscadorDeOrdenesDeServicioComponent } from "./../BuscadorDeOrdenesDeServicioComponent/BuscadorDeOrdenesDeServicioComponent";
import { BuscadorDeOrdenesDeServicioHookComponent } from "./../BuscadorDeOrdenesDeServicioHookComponent/BuscadorDeOrdenesDeServicioHookComponent";
import * as yup from "yup";
import { toast } from "react-toastify";
import { AuthContext } from "./../../auth/authContext";
import { imprimirTicket } from "./../../API/ApiImpresoraTickets";
import { nuevoPagoOrdenServicio } from "../../API/ApiPagosOrdenes";
import { DateTime } from "luxon";
import { useFormik } from "formik";
import jwt_decode from "jwt-decode";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const validationSchema = yup.object({
  noRecibo: yup
    .string("Ingresa Número Recibo")
    .required("Número Recibo requerido"),
  folio: yup.string("Ingresa Folio").required("Folio requerido"),
  fechaPago: yup.string("Ingresa Fecha Pago").required("Precio requerido"),
  abono1: yup
    .number("Valor Númerico")
    .positive("Valor Negativo")
    .required("Monto requerido")
    .min(1, "Solo números positivos"),
  abonoIva: yup
    .number("Valor Númerico")
    .positive("Valor Negativo")
    .min(0, "Solo números positivos"),
  recibio: yup.string("Ingresa Recibió").required("Recibió requerido"),
  idtipoPago: yup.string("Ingresa monto").required("Monto requerido"),
});

const AddPaymentOrdenServicioComponent = () => {
  const [numeroOrdenServicio, setNumeroOrdenServicio] = React.useState("");

  const { user, dispatch } = React.useContext(AuthContext);

  var decoded = jwt_decode(user.accessToken);

  const [isDisabled, setIsDisabled] = React.useState(false);
  const [finalDataServicios, setFinalDataServicios] = React.useState([]);

  const formik = useFormik({
    initialValues: {
      noRecibo: "0",
      folio: numeroOrdenServicio,
      fechaPago: DateTime.now().toISODate(),
      abono1: "",
      idtipoPago: "1",
      letra: "C",
      abonoIva: "0",
      recibio: decoded.nombre,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setIsDisabled(true);

      nuevoPagoOrdenServicio(user, values).then((resultado) => {
        if (resultado.status == 200) {
          var datosImpresion = {
            tipoImagen: 1,
            razonSocial: values.noRecibo.includes("SM") ? 2 : 1,
            datosServicios: resultado.data.serviciosContratados,
            totalServicios:
              resultado.data.totalServicios === null
                ? 0
                : resultado.data.totalServicios,
            datosRecibo: [
              {
                nombre: "Fecha Pago",
                valor: resultado.data.fechaPago,
              },
              {
                nombre: "Folio Recibo",
                valor: resultado.data.noRecibo,
              },
              {
                nombre: "Folio Orden Servicio",
                valor: resultado.data.folio,
              },
              {
                nombre: "No. Contrato",
                valor:
                  resultado.data.detalleOrden[0]?.serie === null
                    ? ""
                    : resultado.data.detalleOrden[0]?.serie +
                        " " +
                        resultado.data.detalleOrden[0]?.noContrato ===
                      null
                    ? ""
                    : resultado.data.detalleOrden[0]?.noContrato === null,
              },
              {
                nombre: "Nombre Finado",
                valor: resultado.data.detalleOrden[0]?.nombreFinado,
              },
              {
                nombre: "Nombre Titular",
                valor:
                  resultado.data.detalleOrden[0]?.nombreFinado == null
                    ? ""
                    : resultado.data.detalleOrden[0]?.nombreFinado,
              },
              {
                nombre: "Recibe",
                valor:
                  resultado.data.recibio === null ? "" : resultado.data.recibio,
              },
              {
                nombre: "Saldo Anterior",
                valor:
                  "$ " +
                  parseFloat(
                    resultado.data.saldoAnterior === null
                      ? 0
                      : resultado.data.saldoAnterior
                  )
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    .toString(),
              },
              {
                nombre: "Abono",
                valor:
                  "$ " +
                  parseFloat(
                    resultado.data.abono1 === null ? 0 : resultado.data.abono1
                  )
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    .toString(),
              },
              {
                nombre: "Abono IVA",
                valor:
                  "$ " +
                  parseFloat(
                    resultado.data.abonoIva === null
                      ? 0
                      : resultado.data.abonoIva
                  )
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    .toString(),
              },

              {
                nombre: "Saldo Actual",
                valor:
                  "$ " +
                  parseFloat(
                    resultado.data.saldoActual === null
                      ? 0
                      : resultado.data.saldoActual
                  )
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    .toString(),
              },
              {
                nombre: "Método Pago",
                valor:
                  resultado.data.idtipoPago == 1
                    ? "Efectivo"
                    : resultado.data.idtipoPago == 2
                    ? "Tarjeta"
                    : "N/A",
              },
            ],
            datosCodigoBarra: "OS-" + resultado.data.folio,
            idpago: resultado.data.id,
          };

          imprimirTicket(datosImpresion).then((resultado) => {
            toast.success("Pago Agregado a la orden exitosamente");
            resetForm();
            setIsDisabled(false);
          });
        }
      });
    },
  });

  React.useEffect(() => {
    formik.setFieldValue("folio", numeroOrdenServicio);
  }, [numeroOrdenServicio]);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <BuscadorDeOrdenesDeServicioHookComponent
              setNumeroOrdenServicio={setNumeroOrdenServicio}
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12">
            {isDisabled == true ? (
              <>
                <Alert className="mb-3" severity="info">
                  Procesando información...
                </Alert>
              </>
            ) : null}
            <hr />
          </div>
        </div>
        <div className="row">
          <form onSubmit={formik.handleSubmit} className="container-fluid">
            <div className="row row-cols-1">
              <div className="col-6">
                <TextField
                  size="small"
                  fullWidth
                  id="letra"
                  name="letra"
                  label="Letra"
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.letra}
                  onChange={formik.handleChange}
                  variant="standard"
                  select
                  error={formik.touched.letra && Boolean(formik.errors.letra)}
                  helperText={formik.touched.letra && formik.errors.letra}
                  disabled={isDisabled}
                >
                  <MenuItem value={"C"}>C</MenuItem>
                  <MenuItem value={"SM"}>SM</MenuItem>
                </TextField>
              </div>

              <div className="col-6">
                <TextField
                  size="small"
                  fullWidth
                  id="noRecibo"
                  name="noRecibo"
                  label="No. Recibo"
                  type={"number"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.noRecibo}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.noRecibo && Boolean(formik.errors.noRecibo)
                  }
                  helperText={formik.touched.noRecibo && formik.errors.noRecibo}
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="folio"
                  name="folio"
                  label="Folio"
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.folio}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={formik.touched.folio && Boolean(formik.errors.folio)}
                  helperText={formik.touched.folio && formik.errors.folio}
                  disabled={true}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="fechaPago"
                  name="fechaPago"
                  label="Fecha Pago"
                  type={"date"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.fechaPago}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.fechaPago && Boolean(formik.errors.fechaPago)
                  }
                  helperText={
                    formik.touched.fechaPago && formik.errors.fechaPago
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="abono1"
                  name="abono1"
                  label="Abono"
                  type={"number"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.abono1}
                  onChange={formik.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    inputProps: { min: 0, step: "any" },
                  }}
                  variant="standard"
                  error={formik.touched.abono1 && Boolean(formik.errors.abono1)}
                  helperText={formik.touched.abono1 && formik.errors.abono1}
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="abonoIva"
                  name="abonoIva"
                  label="Abono IVA"
                  type={"number"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.abonoIva}
                  onChange={formik.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    inputProps: { min: 0, step: "any" },
                  }}
                  variant="standard"
                  error={
                    formik.touched.abonoIva && Boolean(formik.errors.abonoIva)
                  }
                  helperText={formik.touched.abonoIva && formik.errors.abonoIva}
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="recibio"
                  name="recibio"
                  label="Recibió"
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.recibio}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.recibio && Boolean(formik.errors.recibio)
                  }
                  helperText={formik.touched.recibio && formik.errors.recibio}
                  disabled={isDisabled}
                />
              </div>

              <div className="col">
                <FormControl fullWidth variant="standard">
                  <InputLabel id="idtipoPago">Tipo de Pago</InputLabel>
                  <Select
                    labelId="idtipoPago"
                    id="idtipoPago"
                    value={formik.values.idtipoPago}
                    label="Tipo de Pago"
                    onChange={(e) => {
                      formik.setFieldValue("idtipoPago", e.target.value);
                    }}
                  >
                    <MenuItem value={1}>Efectivo</MenuItem>
                    <MenuItem value={2}>Tarjeta</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <br />
              <br />
              <div className="col mt-2">
                <button type="submit" className="btn btn-sm btn-success w-100">
                  Agregar Pago
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddPaymentOrdenServicioComponent;
