import React from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { AuthContext } from "../../auth/authContext";
import {
  reporteCobranzaRangoFecha,
  reporteCobranzaSerie,
  reportePagosOrdenesServicioPorFechaAPI,
  reporteResumenSerie,
  reporteVentasMantenimientoPorFechaAPI,
  reporteVentasOrdenesServicioPorFechaAPI,
  reporteVentasPrevisionPorFechaAPI,
} from "../../API/ApiReportes";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { LoadingComponent } from "../../Components/LoadingComponent/LoadingComponent";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { SinPermisoComponent } from "../../Components/SinPermisoComponent/SinPermisoComponent";
import MultiselectSeriesComponent from "../../Components/MultiselectSeriesComponent/MultiselectSeriesComponent";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import imageVitanova from "../../Images/LogotipoNegro.png";

import imagenVitaNova from "../../Images/LogotipoNegro.png";
import { ButtonDownloadToXlsComponent } from "../../Components/ButtonDownloadToXlsComponent/ButtonDownloadToXlsComponent";

const guidVista = "ef433e95-e3b0-4caa-a533-3b599add112e";

const ListToPrintComponent = React.forwardRef((props, ref) => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [totalSalarios, setTotalSalarios] = React.useState(0.0);

  return (
    <div className="print-source " ref={ref}>
      <img
        alt="Vitanova Logo"
        className="mt-3"
        src={imageVitanova}
        width="150"
      />
      <br />

      <h3 className="mt-3">Reporte total ventas previsión por fecha</h3>
      <h6 className="mb-4">
        <small>Reporte general</small>
      </h6>
      <h6 className="mb-4">
        <small>
          de: {props.fechaInicio} a: {props.fechaFin}
        </small>
      </h6>

      <div className="container-fluid">
        <div className="row row-cols-5">
          <div className="col">
            <strong>Contrato</strong>
          </div>
          <div className="col">
            <strong>Fecha</strong>
          </div>
          <div className="col">
            <strong>Importe</strong>
          </div>
          <div className="col">
            <strong>Pagado</strong>
          </div>
          <div className="col">
            <strong>Saldo</strong>
          </div>
        </div>
      </div>
      {props.value.map((empleado, index) => (
        <div className="container-fluid" key={index}>
          <div className="row row-cols-5">
            <div className="col">{empleado.contrato}</div>
            <div className="col">{empleado.fechaContrato}</div>
            <div className="col">
              {"$ "}{" "}
              {parseFloat(empleado.totalContrato)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </div>
            <div className="col">
              {"% "}{" "}
              {parseFloat(empleado.pagado)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </div>
            <div className="col">
              {"$ "}{" "}
              {parseFloat(empleado.saldoContrato)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </div>
          </div>
        </div>
      ))}
      <div className="container-fluid">
        <div className="row row-cols-5">
          <div className="col">
            <strong>Total:</strong>
          </div>
          <div className="col"></div>
          <div className="col">
            {"$ "}{" "}
            {parseFloat(props.granTotalImporteTotal)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </div>
          <div className="col">
            {"$ "}{" "}
            {parseFloat(props.granTotalPagadoTotal)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </div>

          <div className="col">
            {"$ "}{" "}
            {parseFloat(props.granTotalSaldoTotal)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </div>
        </div>
      </div>
      <br />
    </div>
  );
});

const ComponentToPrint = React.forwardRef((props, ref) => {
  const { user, dispatch } = React.useContext(AuthContext);

  return (
    <div className="print-source " ref={ref}>
      <img alt="Vitanova Logo" src={imageVitanova} width="150" />
      <br />
      <br />
      <br />
      <h3>Reporte total mantenimiento</h3>
      <br />
      <strong>
        {" "}
        de: {props.fechaInicio} a: {props.fechaFin}
      </strong>
      <br />
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td>Contrato</td>
            <td>{props.value.noContrato}</td>
          </tr>
          <tr>
            <td>Fecha Pago</td>
            <td>{props.value.fechaPago}</td>
          </tr>
          <tr>
            <td>Importe</td>
            <td>
              {" "}
              {"$ "}
              {parseFloat(props.value.monto)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </td>
          </tr>
          <tr>
            <td>folio</td>
            <td>
              {" "}
              {"$ "}
              {parseFloat(props.value.folio)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </td>
          </tr>
          <tr>
            <td>Descuento</td>
            <td>
              {" "}
              {"$ "}
              {parseFloat(props.value.descuento)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </td>
          </tr>
          <tr>
            <td>Justificación</td>
            <td>{props.value.justificacion}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <br />
      <br />
      __________________________________________________________________
      <br />
      Firma
      <br />
      <br />
      <small style={{ fontSize: "10px" }}>Impreso por: {user.username}</small>
    </div>
  );
});

const ComponentToPrintWrapper = (props) => {
  const componentRef = React.useRef();

  const [totalServicios, setTotalServicios] = React.useState(0.0);

  return (
    <>
      <div className="col-12 mb-2">
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <span>
              <strong> Orden: {props.item.folio} </strong>
              <br />
              Nombre Finado: {props.item.nombreFinado}
              <br />
              Nombre Solicitante: {props.item.nombreFinado}
            </span>
            <span>
              <strong>Fecha: {props.item.fecha} </strong>
            </span>
          </div>
          <div className="card-body">
            <div className="container-fliud">
              <div className="row">
                <div className="col-md-7">
                  <h6>Pagos Realizados</h6>
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th scope="col">Recibo</th>
                        <th scope="col">Fecha Pago</th>
                        <th scope="col">Subtotal</th>
                        <th scope="col">IVA</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.item.pagosOrden.map((pago) => (
                        <tr key={pago.idpago}>
                          <th scope="row">{pago.noRecibo}</th>
                          <th scope="row">{pago.fechaPago}</th>
                          <td>
                            ${" "}
                            {parseFloat(pago.totalSinAbonoIva)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </td>
                          <td>
                            ${" "}
                            {parseFloat(pago.abonoIva)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </td>
                          <td>
                            ${" "}
                            {parseFloat(pago.totalConAbonoIva)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </td>
                        </tr>
                      ))}

                      <tr>
                        <th scope="row"></th>
                        <th scope="row"></th>
                        <td>
                          ${" "}
                          {parseFloat(props.item.totalSinAbonoIvaTotal)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </td>
                        <td>
                          ${" "}
                          {parseFloat(props.item.totalAbonoIVA)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </td>

                        <td>
                          ${" "}
                          {parseFloat(props.item.totalConAbonoIvaTotal)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-5">
                  <h6>Servicios Realizados</h6>
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th scope="col">Recibo</th>
                        <th scope="col">Nombre Servicio</th>
                        <th scope="col">Precio</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.item.serviciosContratados.map((pago) => (
                        <tr key={pago.idpago}>
                          <th scope="row">{pago.idservicio}</th>
                          <th scope="row">{pago.nombreServicio}</th>
                          <td>
                            ${" "}
                            {parseFloat(pago.precio)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </td>
                        </tr>
                      ))}

                      <tr>
                        <th scope="row"></th>
                        <th scope="row"></th>
                        <td>
                          ${" "}
                          {parseFloat(props.item.totalServicios)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ReporteOrdenesServicioScreenComponent = () => {
  const componentRefListado = React.useRef();

  const todayInicio = new Date();
  const todayFin = new Date();
  const numberOfDaysToAdd = 15;
  const dateInicio = todayInicio.setDate(
    todayInicio.getDate() - numberOfDaysToAdd
  );
  const dateFin = todayFin.setDate(todayFin.getDate());

  const defaultValueInicio = new Date(dateInicio).toISOString().split("T")[0]; // yyyy-mm-dd

  const defaultValueFin = new Date(dateFin).toISOString().split("T")[0]; // yyyy-mm-dd

  const { user, dispatch } = React.useContext(AuthContext);
  const [reporteSerie, setReporteSerie] = React.useState("");
  const [reporteFechaInicial, setReporteFechaInicial] =
    React.useState(defaultValueInicio);
  const [reporteFechaFin, setReporteFechaFin] = React.useState(defaultValueFin);
  const [finalData, setFinalData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [checkPersmissions, setCheckPermissions] = React.useState([]);
  const [permisoParaVisualizar, setPermisoParaVisualizar] =
    React.useState(false);
  const [permisoReporte, setPermisoReporte] = React.useState(false);
  const [seriesSeleccionadas, setSeriesSeleccionadas] = React.useState([]);
  const [granTotalComisionMostrar, setGranTotalComisionMostrar] =
    React.useState(0.0);

  const [granTotalTotalMostrar, setGranTotalTotalMostrar] = React.useState(0.0);

  const [listoParaMandar, setListoParaMandar] = React.useState(false);

  const [granTotalImporteTotal, setGranTotalImporteTotal] = React.useState(0.0);
  const [granTotalPagadoTotal, setGranTotalPagadoTotal] = React.useState(0.0);
  const [granTotalSaldoTotal, setGranTotalSaldoTotal] = React.useState(0.0);

  React.useEffect(() => {
    let listo = false;

    let granTotalImporte = 0.0;
    let granTotalPagado = 0.0;
    let granTotalSaldo = 0.0;

    for (const registro of finalData) {
      granTotalImporte = granTotalImporte + parseFloat(registro.monto);
      granTotalPagado = granTotalPagado + parseFloat(registro.pagado);
      granTotalSaldo = granTotalSaldo + parseFloat(registro.saldoContrato);
    }

    setGranTotalImporteTotal(granTotalImporte);

    return () => {
      listo = true;
    };
  }, [finalData]);

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "f804550b-1f5d-493a-8222-32876f4c07bb" &&
              permiso.allowed == 1
            ) {
              setPermisoParaVisualizar(true);
              setPermisoReporte(true);
            }
          }
        }
      }
    );

    let listo = false;

    let granTotalComision = 0.0;
    let granTotaltotal = 0.0;

    setGranTotalTotalMostrar(granTotaltotal);
    setGranTotalComisionMostrar(granTotalComision);
    return () => {
      listo = true;
    };
  }, []);

  return (
    <>
      {permisoParaVisualizar == true && permisoReporte == true ? (
        <>
          <div className="container-fluid mb-2 d-none d-print-block">
            <div className="row">
              <div className="col-12">
                <img src={imagenVitaNova} alt="Logo Vita Nova" height={30} />
              </div>
            </div>
          </div>
          <div className="container-fluid mb-3 mt-2">
            <div className="row row-cols-md-3 d-flex align-items-center">
              <div className="col">
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="outlined-basic"
                  label="Fecha Inicio"
                  type="date"
                  variant="standard"
                  value={reporteFechaInicial}
                  onChange={(e) => {
                    setReporteFechaInicial(e.target.value);
                  }}
                />
              </div>
              <div className="col">
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="outlined-basic"
                  label="Fecha Fin"
                  type="date"
                  variant="standard"
                  value={reporteFechaFin}
                  onChange={(e) => {
                    setReporteFechaFin(e.target.value);
                  }}
                />
              </div>
              <div className="col d-print-none">
                <button
                  disabled={listoParaMandar}
                  className="btn btn-sm btn-success w-100"
                  onClick={() => {
                    setLoading(true);
                    reportePagosOrdenesServicioPorFechaAPI(user, {
                      fechaInicial: reporteFechaInicial,
                      fechaFinal: reporteFechaFin,
                    }).then((resultado) => {
                      
                      if (resultado.status == 200) {
                        setFinalData(resultado.data);
                      }
                      setLoading(false);
                    });
                  }}
                >
                  {" "}
                  Buscar{" "}
                </button>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-sm btn-info my-3">
                      Imprimir Reporte General
                    </button>
                  )}
                  content={() => componentRefListado.current}
                />
                <ListToPrintComponent
                  ref={componentRefListado}
                  value={finalData}
                  fechaInicio={reporteFechaInicial}
                  fechaFin={reporteFechaFin}
                  granTotalImporteTotal={granTotalImporteTotal}
                  granTotalPagadoTotal={granTotalPagadoTotal}
                  granTotalSaldoTotal={granTotalSaldoTotal}
                />
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row row-cols-md-1">
              <div className="col" style={{ textAlign: "right" }}>
                <ButtonDownloadToXlsComponent
                  data={finalData}
                  fileName="Reporte de Mantenimiento"
                />
              </div>
            </div>
                      
          </div>

          <div className="container-fluid">
            <div className="row row-cols-1">
              {finalData.map((row, index) => (
                <ComponentToPrintWrapper
                  key={index}
                  item={row}
                  fechaInicio={reporteFechaInicial}
                  fechaFin={reporteFechaFin}
                  granTotalImporteTotal={granTotalImporteTotal}
                  granTotalPagadoTotal={granTotalPagadoTotal}
                  granTotalSaldoTotal={granTotalSaldoTotal}
                />
              ))}
            </div>
          </div>
          {loading == true ? <LoadingComponent /> : null}
        </>
      ) : (
        <SinPermisoComponent />
      )}
    </>
  );
};
