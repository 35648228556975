import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { AuthContext } from "../../auth/authContext";
import {
  reporteCobranzaRangoFecha,
  reporteCuboComisionesContratos,
  reporteCuboComisionesOrdenes,
  reporteNomina,
  reporteNominaSueldos,
  reporteResumenSerie,
} from "../../API/ApiReportes";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { LoadingComponent } from "../../Components/LoadingComponent/LoadingComponent";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { SinPermisoComponent } from "../../Components/SinPermisoComponent/SinPermisoComponent";
import { BuscadorDeOrdenesDeServicioHookComponent } from "../../Components/BuscadorDeOrdenesDeServicioHookComponent/BuscadorDeOrdenesDeServicioHookComponent";
import { getAllEmpleadosParaSelect } from "../../API/ApiEmpleados";
import { BuscadorDeContratosParaPagoComponent } from "../../Components/BuscadorDeContratosParaPagoComponent/BuscadorDeContratosParaPagoComponent";
import { BuscadorDeContratosParaReporteComponent } from "../../Components/BuscadorDeContratosParaReporteComponent/BuscadorDeContratosParaReporteComponent";
import { toast } from "react-toastify";
import imageVitanova from "../../Images/LogotipoNegro.png";

import ReactToPrint from "react-to-print";
import { ButtonDownloadToXlsComponent } from "../../Components/ButtonDownloadToXlsComponent/ButtonDownloadToXlsComponent";

const guidVista = "ef433e95-e3b0-4caa-a533-3b599add112e";

const ListToPrintComponent = React.forwardRef((props, ref) => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [totalSalarios, setTotalSalarios] = React.useState(0.0);

  useEffect(() => {
    var totalSalarios = 0.0;

    for (const salario of props.value) {
      totalSalarios = totalSalarios + parseFloat(salario.salario);
    }

    setTotalSalarios(totalSalarios);
  }, [props]);

  return (
    <div className="print-source " ref={ref}>
      <img
        alt="Vitanova Logo"
        className="mt-3"
        src={imageVitanova}
        width="150"
      />
      <br />

      <h3 className="mt-3">Reporte de nómina</h3>
      <h6>Fecha: {props.fecha}</h6>
      {props.value.map((empleado, index) => (
        <div className="container" key={index}>
          <div className="row">
            <div className="col-6">
              {empleado.nombre} {empleado.apaterno} {empleado.amaterno}
            </div>
            <div className="col-6">
              {"$ "}{" "}
              {parseFloat(empleado.salario)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </div>
          </div>
        </div>
      ))}
      <div className="container small">
        <div className="row">
          <div className="col-6">
            <strong>Total:</strong>
          </div>
          <div className="col-6">
            {"$ "}{" "}
            {parseFloat(totalSalarios)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </div>
        </div>
      </div>
      <br />
    </div>
  );
});

const ComponentToPrint = React.forwardRef((props, ref) => {
  const { user, dispatch } = React.useContext(AuthContext);

  return (
    <div className="print-source " ref={ref}>
      <img alt="Vitanova Logo" src={imageVitanova} width="150" />
      <br />
      <br />
      <strong>Fecha de Impresion: {props.fecha}</strong>
      <br />
      <strong>CURP: </strong> {props.value.curp}
      <br />
      <strong>RFC: </strong>
      {props.value.rfc}
      <br />
      <strong>Domicilio: </strong>
      {props.value.domicilio}
      <br />
      <strong>Nacimiento: </strong>
      {props.value.fechaNacimiento}
      <br />
      <strong>
        {props.value.nombre} {props.value.apaterno} {props.value.amaterno}
      </strong>
      <br />
      <br />
      Salario: {"$ "}{" "}
      {parseFloat(props.value.salario)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
      <br />
      <br />
      <br />
      <div className="text-center">
        _____________________________________________________________
        <br />
        Firma
      </div>
      <br />
      <br />
      <br />
      <small style={{ fontSize: "10px" }}>Impreso por: {user.username}</small>
    </div>
  );
});

const ComponentToPrintWrapper = (props) => {
  const componentRef = React.useRef();

  return (
    <div className="row mb-1">
      <div className="col-md-5 text-center">
        {props.item.nombre} {props.item.apaterno} {props.item.amaterno}
      </div>
      <div className="col-md-5 text-center">
        <span className="text-end">
          {"$ "}
          {parseFloat(props.item.salario)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
        </span>
      </div>
      <div className="col-md-2 text-center">
        <ReactToPrint
          trigger={() => (
            <button type="primary" className="btn btn-sm btn-success">
              Imprimir Recibo
            </button>
          )}
          content={() => componentRef.current}
        />
        <ComponentToPrint
          ref={componentRef}
          value={props.item}
          fecha={props.fecha}
        />
      </div>
    </div>
  );
};

export const ReporteDeNominaSoloSalarioScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [reporteSerie, setReporteSerie] = React.useState("");
  const [reporteFechaInicial, setReporteFechaInicial] = React.useState("");
  const [reporteFechaFin, setReporteFechaFin] = React.useState("");
  const [numeroOrdenServicio, setNumeroOrdenServicio] = React.useState("");
  const [finalData, setFinalData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [checkPersmissions, setCheckPermissions] = React.useState([]);
  const [permisoParaVisualizar, setPermisoParaVisualizar] =
    React.useState(false);
  const [permisoReporte, setPermisoReporte] = React.useState(false);
  const [idempleadoFound, setIdempleadoFound] = React.useState("");
  const [granTotalComisionMostrar, setGranTotalComisionMostrar] =
    React.useState(0.0);

  const [granTotalTotalMostrar, setGranTotalTotalMostrar] = React.useState(0.0);

  const [listadoEmpleados, setListadoEmpleados] = React.useState([]);

  const componentRefListado = React.useRef();

  const todayInicio = new Date();
  const todayFin = new Date();
  const numberOfDaysToAdd = 1;
  const dateInicio = todayInicio.setDate(
    todayInicio.getDate() - numberOfDaysToAdd
  );
  const dateFin = todayFin.setDate(todayFin.getDate());

  const todayDate = new Date(dateInicio).toISOString().split("T")[0]; // yyyy-mm-dd

  const defaultValueFin = new Date(dateFin).toISOString().split("T")[0]; // yyyy-mm-dd

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          var resultadoPermiso = false;

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "79f9744f-08de-47b6-bcb3-ae50c9e5d268" &&
              permiso.allowed == 1
            ) {
              resultadoPermiso = true;

              setPermisoReporte(true);

              setPermisoParaVisualizar(true);
            }
          }

          var hola = resultadoPermiso;

          setPermisoReporte(true);
        }
      }
    );

    let listo = false;

    return () => {
      listo = true;
    };

    return () => {
      ready = true;
    };
  }, []);

  React.useEffect(() => {
    let listo = false;

    let granTotalComision = 0.0;
    let granTotaltotal = 0.0;

    for (const serie of finalData) {
      granTotaltotal = granTotaltotal + parseFloat(serie.salario);
    }
    setGranTotalTotalMostrar(granTotaltotal);
    setGranTotalComisionMostrar(granTotalComision);

    return () => {
      listo = true;
    };
  }, [finalData]);

  React.useEffect(() => {
    let ready = false;

    getAllEmpleadosParaSelect(user).then((resultado) => {
      if (resultado.status == 200) {
        setListadoEmpleados(resultado.data);
      } else {
        setListadoEmpleados([]);
      }
    });

    return () => {
      ready = true;
    };
  }, []);

  return (
    <>
      {permisoParaVisualizar == true && permisoReporte == true ? (
        <>
          <div className="container-fluid mb-3 mt-2">
            <div className="row row-cols-md-1 d-flex align-items-center">
              <div className="col mb-2 d-none">
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="outlined-basic"
                  label="Fecha Inicio"
                  type="date"
                  variant="standard"
                  value={reporteFechaInicial}
                  onChange={(e) => {
                    setReporteFechaInicial(e.target.value);
                  }}
                />
              </div>
              <div className="col mb-2 d-none">
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="outlined-basic"
                  label="Fecha Fin"
                  type="date"
                  variant="standard"
                  value={reporteFechaFin}
                  onChange={(e) => {
                    setReporteFechaFin(e.target.value);
                  }}
                />
              </div>

              <div className="col mb-2">
                <button
                  className="btn btn-sm btn-success w-100"
                  onClick={() => {
                    setLoading(true);
                    reporteNominaSueldos(user).then((resultado) => {
                      if (resultado.status == 200) {
                        setFinalData(resultado.data);
                        setLoading(false);
                      }
                    });
                  }}
                >
                  {" "}
                  Buscar{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row row-cols-md-1">
              <div className="col" style={{ textAlign: "right" }}>
                <ButtonDownloadToXlsComponent
                  data={finalData}
                  fileName="Reporte nómina quincenal (General, Particular)"
                />
              </div>
            </div>
          </div>
          <ReactToPrint
            trigger={() => (
              <button className="btn btn-sm btn-info my-3">
                Imprimir Reporte General
              </button>
            )}
            content={() => componentRefListado.current}
          />
          <ListToPrintComponent
            ref={componentRefListado}
            value={finalData}
            fecha={todayDate}
          />
          <div className="container-fluid">
            <div className="row row-cols-1">
              <div className="row mb-1">
                <div className="col-md-5 text-center">
                  <strong>Nombre</strong>
                </div>
                <div className="col-md-5 text-center">
                  <strong>Salario</strong>
                </div>
                <div className="col-md-2 text-center">
                  <strong>Acciones</strong>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row row-cols-1">
              {finalData.map((empleado, index) => (
                <ComponentToPrintWrapper
                  key={index}
                  item={empleado}
                  fecha={todayDate}
                />
              ))}
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-5"></div>
                <div className="col-sm-4"></div>
                <div className="col-sm-4"></div>
              </div>
            </div>
          </div>

          <hr />

          <div className="container-fluid">
            <div className="row row-cols-1">
              <div className="row mb-1">
                <div className="col-md-5 text-center">
                  <strong>Total: </strong>
                </div>
                <div className="col-md-5 text-center">
                  {"$ "}
                  {granTotalTotalMostrar
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                </div>
                <div className="col-md-2 text-center"></div>
              </div>
            </div>
          </div>

          {loading == true ? <LoadingComponent /> : null}
        </>
      ) : (
        <SinPermisoComponent />
      )}
    </>
  );
};
