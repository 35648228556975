import React from "react";
import imagen from "../../Images/LogotipoNegro.png";
import styles from "./InicioScreenComponent.module.css";
export const InicioScreenComponent = () => {
  return (
    <>
      <div className={styles.fondoPrincipal}>
        <div className={`${styles.center} container-fluid`}>
          <div className="row">
            <div
              className="col-sm-12 text-center"
              style={{ paddingTop: "10vh" }}
            >
              <img
                src={imagen}
                alt="imagen Vitanova"
                style={{ width: "90%" }}
              />
              <br />
              <br />
              <br />
              <h4>Bienvenido a tu sistema</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
