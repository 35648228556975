import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import { getDetailContract } from "../../API/ApiContratos";
import { getDetalleOrdenesDeServicio } from "../../API/ApiOrdenesServicio";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { AuthContext } from "../../auth/authContext";
import { EditarOrdenServicioDatosFinado } from "../../Components/EditarOrdenServicioComponent/EditarOrdenServicioDatosFinado";
import { EditarPagosOrdenServicioComponent } from "../../Components/EditarPagosOrdenServicioComponent/EditarPagosOrdenServicioComponent";
import { EditarServiciosOrdenServicioComponent } from "../../Components/EditarServiciosOrdenServicioComponent/EditarServiciosOrdenServicioComponent";
import { EditarSolicitanteOrdenServicioComponent } from "../../Components/EditarSolicitanteOrdenServicioComponent/EditarSolicitanteOrdenServicioComponent";
import { EditDatosDeDefuncionServicioComponent } from "../../Components/EditDatosDeDefuncionServicioComponent/EditDatosDeDefuncionServicioComponent";
import { LoadingComponent } from "../../Components/LoadingComponent/LoadingComponent";
import { PersonalServicioOrdenServicioComponent } from "../../Components/PersonalServicioOrdenServicioComponent/PersonalServicioOrdenServicioComponent";

const guidVista = "d5376ba2-cefd-4edd-8402-362d5bd4c75d";

export const DetalleOrdenServicioScreenComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(true);
  let { idordenservicio } = useParams();
  let { tababrir } = useParams();

  const [finalData, setFinalData] = React.useState([]);
  const [contadorActualizar, setContadorActualizar] = React.useState(0);

  React.useEffect(() => {
    let ready = false;

    getDetalleOrdenesDeServicio(user, idordenservicio).then((resultado) => {
      if (resultado.status == 200) {

debugger;
         
        setFinalData(resultado.data);
        setLoading(false);
      }
    });

    return () => {
      ready = true;
    };
  }, []);

  const [permisoParaAgregar, setPermisoParaAgregar] = React.useState(false);
  const [permisoParaVisualizar, setPermisoParaVisualizar] =
    React.useState(false);
  const [permisoParaEditar, setPermisoParaEditar] = React.useState(false);
  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  const [agregarPagoAOrdenServicio, setAgregarPagoAOrdenServicio] =
    React.useState(false);
  const [editarPagoAOrdenServicio, setEditarPagoAOrdenServicio] =
    React.useState(false);
  const [totalServicios, setTotalServicios] = React.useState(0);
  const [
    agregarComisionistaAOrdenServicio,
    setAgregarComisionistaAOrdenServicio,
  ] = React.useState(false);
  const [editarComisionAOrdenServicio, setEditarComisionAOrdenServicio] =
    React.useState(false);
  const [agregarServicioAOrdenDeServicio, setAgregarServicioAOrdenDeServicio] =
    React.useState(false);
  const [editarServicioAOrdenDeServicio, setEditarServicioAOrdenDeServicio] =
    React.useState(false);

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "3a7e89a7-f78d-4d76-861d-23d227c839d7" &&
              permiso.allowed == 1
            ) {
              setPermisoParaAgregar(true);
            }
            if (
              permiso.guidFuncion == "0e25097d-f4e0-48a5-a333-e88c5344a89c" &&
              permiso.allowed == 1
            ) {
              setPermisoParaVisualizar(true);
            }
            if (
              permiso.guidFuncion == "de774da3-8f3a-438b-a392-90a181b53081" &&
              permiso.allowed == 1
            ) {
              setPermisoParaEditar(true);
            }

            //Agregar PAgo a Orden de Servicio
            if (
              permiso.guidFuncion == "53581949-a479-4572-af6e-55215818e49f" &&
              permiso.allowed == 1
            ) {
              setAgregarPagoAOrdenServicio(true);
            }
            //Editar PAgo a Orden de Servicio
            if (
              permiso.guidFuncion == "536d1656-c902-499f-9219-1eda2233a2d0" &&
              permiso.allowed == 1
            ) {
              setEditarPagoAOrdenServicio(true);
            }
            //Agregar Comisionista
            if (
              permiso.guidFuncion == "f22615e0-e4e2-4b9b-96ee-d6f2b07d3e64" &&
              permiso.allowed == 1
            ) {
              setAgregarComisionistaAOrdenServicio(true);
            }
            //Editar Comisión a Orden de Servicio
            if (
              permiso.guidFuncion == "b9ab7b4b-e34f-49e7-87a9-bc16261533ed" &&
              permiso.allowed == 1
            ) {
              setEditarComisionAOrdenServicio(true);
            }
            //Agregar Servicio a Orden de Servicio
            if (
              permiso.guidFuncion == "bcf2e889-5933-417e-870b-88d987576239" &&
              permiso.allowed == 1
            ) {
              setAgregarServicioAOrdenDeServicio(true);
            }
            //EditarOrdenDeServicio
            if (
              permiso.guidFuncion == "683b2d74-0ba5-42dc-9f3e-46cbd5433132" &&
              permiso.allowed == 1
            ) {
              setEditarServicioAOrdenDeServicio(true);
            }
          }
        }
      }
    );

    return () => {
      ready = true;
    };
  }, []);

  return (
    <>
      {loading == true ? (
        <LoadingComponent />
      ) : (
        <>
          <ul className="nav nav-tabs mt-2" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${tababrir == "false" ? "active" : null}`}
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Datos del Finado
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${tababrir == "true" ? "active" : null}`}
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Datos de Defunción
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#contact"
                type="button"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                Servicios
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pagos-tab"
                data-bs-toggle="tab"
                data-bs-target="#pagos"
                type="button"
                role="tab"
                aria-controls="pagos"
                aria-selected="false"
              >
                Solicitante
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="personalservicio-tab"
                data-bs-toggle="tab"
                data-bs-target="#personalservicio"
                type="button"
                role="tab"
                aria-controls="personalservicio"
                aria-selected="false"
              >
                Personal Servicio
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="historico-tab"
                data-bs-toggle="tab"
                data-bs-target="#historico"
                type="button"
                role="tab"
                aria-controls="historico"
                aria-selected="false"
              >
                Pagos
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${
                tababrir == "false" ? "show active" : null
              }`}
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <EditarOrdenServicioDatosFinado
                finalData={finalData}
                setFinalData={setFinalData}
                permisoParaEditar={permisoParaEditar}
              />
            </div>
            <div
              className={`tab-pane fade ${
                tababrir == "true" ? "show active" : null
              }`}
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <EditDatosDeDefuncionServicioComponent
                finalData={finalData}
                setFinalData={setFinalData}
                permisoParaEditar={permisoParaEditar}
              />
            </div>
            <div
              className="tab-pane fade"
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <EditarServiciosOrdenServicioComponent
                finalData={finalData}
                setFinalData={setFinalData}
                permisoParaEditar={permisoParaEditar}
                agregarServicioAOrdenDeServicio={
                  agregarServicioAOrdenDeServicio
                }
                totalServicios={totalServicios}
                setTotalServicios={setTotalServicios}
                editarServicioAOrdenDeServicio={editarServicioAOrdenDeServicio}
              />

             
            </div>
            <div
              className="tab-pane fade"
              id="pagos"
              role="tabpanel"
              aria-labelledby="pagos-tab"
            >
              <EditarSolicitanteOrdenServicioComponent
                finalData={finalData}
                setFinalData={setFinalData}
                permisoParaEditar={permisoParaEditar}
              />
            </div>
            <div
              className="tab-pane fade"
              id="personalservicio"
              role="tabpanel"
              aria-labelledby="personalservicio-tab"
            >
              <PersonalServicioOrdenServicioComponent
                finalData={finalData}
                setFinalData={setFinalData}
                permisoParaEditar={permisoParaEditar}
                editarComisionAOrdenServicio={editarComisionAOrdenServicio}
                agregarComisionistaAOrdenServicio={
                  agregarComisionistaAOrdenServicio
                }
                totalServicios={totalServicios}
                setTotalServicios={setTotalServicios}
              />
            </div>
            <div
              className="tab-pane fade"
              id="historico"
              role="tabpanel"
              aria-labelledby="historico-tab"
            >
               <EditarPagosOrdenServicioComponent
                finalData={finalData}
                setFinalData={setFinalData}
                permisoParaEditar={permisoParaEditar}
                agregarPagoAOrdenServicio={agregarPagoAOrdenServicio}
                editarPagoAOrdenServicio={editarPagoAOrdenServicio}
                totalServicios={totalServicios}
                setTotalServicios={setTotalServicios}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
