import { API } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";

export const newContractPayment = async (user, values) => {
  let url = API + "contracts/newPayment";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {


    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};

export const editContractPayment = async (user, values, idpago) => {
  let url = API + "contracts/updatePayment/" + idpago;

  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};

export const getTopPagos = async (user) => {
  let url = API + "contracts/getTopPagos";

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};
export const getTopPagosMantenimiento = async (user) => {
  let url = API + "contracts/getTopPagos";

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};
