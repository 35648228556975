import React from "react";
import {
  agregarEliminarComisionistaAContrato,
  getEmpleadosAsignadosParaComision,
  getEmpleadosParaComision,
} from "../../API/ApiComisiones";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { toast } from "react-toastify";

const validationSchema = yup.object({
  comisionPorcentaje: yup
    .number()
    .integer()
    .min(1, "El valor mínimo es 1")
    .required("Comisión Necesaria"),
  importeContado: yup
    .number()
    .min(1, "El valor mínimo es 1")
    .required("Importe Necesaria"),
});

export const EditarComisionesContratoComponent = (props) => {
  const [importeTotal, setImporteTotal] = React.useState(0.0);

  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [empleadosParaSeleccionar, setEmpleadosParaSeleccionar] =
    React.useState([]);
  const [listadoDeComisionesAgregadas, setListadoDeComisionesAgregadas] =
    React.useState([]);
  const [totalPorcentajeComisiones, setTotalPorcentajeComisiones] =
    React.useState(0.0);

  const [val, setVal] = React.useState({});

  React.useEffect(() => {
    getEmpleadosParaComision(user, props.contrato).then((resultado) => {
      if (resultado.status == 200) {
        setEmpleadosParaSeleccionar(resultado.data);
      } else {
        setEmpleadosParaSeleccionar([]);
      }
    });

    getEmpleadosAsignadosParaComision(user, props.contrato).then(
      (resultado) => {
        if (resultado.status == 200) {
          if (resultado.data.length == 0) {
            setListadoDeComisionesAgregadas([]);
            toast.error("No existen Comisionistas");

            toast("Te pedimos llenar la información de los comisionistas", {
              position: "top-right",
              autoClose: 500000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            setListadoDeComisionesAgregadas(resultado.data);
          }
        }
      }
    );

    let importeSave = 0.0;
    for (const precio of props.finalData.etapaDos) {
      importeSave = importeSave + parseFloat(precio.importe);
    }

    setImporteTotal(importeSave);
  }, []);

  React.useEffect(() => {
    let listo = false;

    let porcentaje = 0.0;
    for (const comision of listadoDeComisionesAgregadas) {
      porcentaje = parseFloat(comision.comisionPorcentaje) + porcentaje;
    }

    setTotalPorcentajeComisiones(porcentaje);

    let importeSave = 0.0;
    for (const precio of props.finalData.etapaDos) {
      importeSave = importeSave + parseFloat(precio.precioContado);
    }

    var valor =
      listadoDeComisionesAgregadas[listadoDeComisionesAgregadas.length - 1];

    let precioFinal = 0.0;

    if (typeof valor === "undefined") {
    } else {
      precioFinal =
        parseFloat(valor.comisionImporte) *
        parseFloat(valor.comisionPorcentaje);
    }

    formik.setFieldValue("importeContado", precioFinal);

    setImporteTotal(importeSave);

    return () => {
      listo = true;
    };
  }, [listadoDeComisionesAgregadas]);

  const formik = useFormik({
    initialValues: {
      comisionPorcentaje: "",
      idcontrato: props.finalData.etapaUno[0].id,
      importeContado: "",
      idEmpleado: "",
      noContrato: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.idEmpleado == "" || values.noContrato == "") {
        toast.error("Debes seleccionar un empleado para asignar la comisión");
      } else {
        setIsDisabled(true);
        agregarEliminarComisionistaAContrato(user, values).then((resultado) => {
          if (resultado.status == 200) {
            toast.success("Hemos agregado la comisión al contrato");

            getEmpleadosAsignadosParaComision(user, props.contrato).then(
              (resultado) => {
                if (resultado.status == 200) {
                  setListadoDeComisionesAgregadas(resultado.data);
                }
              }
            );

            getEmpleadosParaComision(user, props.contrato).then((resultado) => {
              if (resultado.status == 200) {
                setEmpleadosParaSeleccionar(resultado.data);
              } else {
                setEmpleadosParaSeleccionar([]);
              }
            });
            let contadorActualizar = props.contadorActualizar;
            props.setContadorActualizar(contadorActualizar + 1);
          } else {
            toast.error("Ha habido un error");
          }

          setIsDisabled(false);
        });
      }
    },
  });

  return (
    <div className="container-fluid p-2">
      <div className="row">
        <div className="col-md-3">
          <form className="card" onSubmit={formik.handleSubmit}>
            <div className="card-body">
              <Autocomplete
                disablePortal
                val={val}
                id="combo-box-demo"
                className="mb-2"
                onChange={(event, newValue) => {
                  formik.setFieldValue("idEmpleado", newValue.id);
                  formik.setFieldValue("noContrato", props.contrato);
                }}
                options={empleadosParaSeleccionar}
                getOptionLabel={(option) =>
                  option.id +
                  "-" +
                  option.nombre +
                  " " +
                  option.apaterno +
                  " " +
                  option.amaterno
                }
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} label="Buscar empleado" />
                )}
              />

              <TextField
                size="small"
                fullWidth
                id="comisionPorcentaje"
                name="comisionPorcentaje"
                label="Comisión Porcentaje"
                type={"number"}
                className="mb-3"
                value={formik.values.comisionPorcentaje}
                onChange={formik.handleChange}
                variant="standard"
                error={
                  formik.touched.comisionPorcentaje &&
                  Boolean(formik.errors.comisionPorcentaje)
                }
                helperText={
                  formik.touched.comisionPorcentaje &&
                  formik.errors.comisionPorcentaje
                }
                disabled={isDisabled}
              />
              <TextField
                size="small"
                fullWidth
                id="importeContado"
                name="importeContado"
                label="Precio a calcular comisión"
                type={"number"}
                InputProps={{ inputProps: { min: 0 , step: 'any' } }}
                className="mb-3"
                value={formik.values.importeContado}
                onChange={formik.handleChange}
                variant="standard"
                disabled={isDisabled}
                error={
                  formik.touched.importeContado &&
                  Boolean(formik.errors.importeContado)
                }
                helperText={
                  formik.touched.importeContado && formik.errors.importeContado
                }
              />

              {props.permisoParaEditar == true ? (
                <>
                  {props.finalData.etapaDos.length === 0 ? (
                    <button
                      className="my-4 btn btn-success w-100 text-white"
                      disabled={true}
                    >
                      No se puede agregar comisión, <br /> agrega información
                      financiera primero
                    </button>
                  ) : (
                    <button
                      className="my-4 btn btn-success w-100 text-white"
                      type="submit"
                      disabled={isDisabled}
                    >
                      Agregar
                    </button>
                  )}
                </>
              ) : null}
            </div>
          </form>

          <div className="card">
            <div className="card-body">
              Porcentaje total agregado: {totalPorcentajeComisiones} %
              <hr />
              <strong>
                {typeof props.finalData.etapaDos === "undefined" ||
                props.finalData.etapaDos.length === 0
                  ? 0
                  : props.finalData.comisiones.message}
              </strong>
              <br />
              {typeof props.finalData.etapaDos === "undefined" ||
              props.finalData.etapaDos.length === 0
                ? 0
                : "Total de Comisiones: $ " +
                  props.finalData.comisiones.montoComisionesTotal
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              <br />
              {typeof props.finalData.etapaDos === "undefined" ||
              props.finalData.etapaDos.length === 0
                ? 0
                : "Pagos recibidos al momento $ " +
                  props.finalData.comisiones.montoPagosAlMomento
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              <br />
              {typeof props.finalData.etapaDos === "undefined" ||
              props.finalData.etapaDos.length === 0
                ? 0
                : "Pendiente por pagar $ " +
                  props.finalData.comisiones.pendientePorPagar
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </div>
          </div>
        </div>

        <div className="col-md-9">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h3>Comisiones</h3>
              </div>
            </div>
            <div className="row row-cols-md-3">
              {listadoDeComisionesAgregadas.map((comision, index) => (
                <div key={index} className="col mb-2">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button
                          className="btn btn-sm btn-danger"
                          type="button"
                          onClick={() => {
                            var comisionResultado = comision;

                            setIsDisabled(true);
                            agregarEliminarComisionistaAContrato(user, {
                              idEmpleado: comision.idempleado,
                              noContrato: props.contrato,
                              idcontrato: props.finalData.etapaUno[0]?.id,
                              comisionPorcentaje: 0,
                            }).then((resultado) => {
                              if (resultado.status == 200) {
                                toast.success(
                                  "Hemos eliminado la comisión al contrato"
                                );

                                getEmpleadosAsignadosParaComision(
                                  user,
                                  props.contrato
                                ).then((resultado) => {
                                  if (resultado.status == 200) {
                                    setListadoDeComisionesAgregadas(
                                      resultado.data
                                    );
                                  }
                                });
                              } else {
                                toast.error("Ha habido un error");
                              }

                              setIsDisabled(false);
                            });
                          }}
                        >
                          Eliminar
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      {comision.idempleado} - {comision.nombre}{" "}
                      {comision.apaterno} {comision.amaterno}
                      <hr className="m-0 p-0 mt-2 mb-2" />
                      Comisión: {comision.comisionPorcentaje} % <br />
                      Importe: ${" "}
                      {parseFloat(comision.comisionImporte)
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
                      <br />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
