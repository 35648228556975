import React from "react";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import styles from "./PanelDeControlScreenComponent.module.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../../auth/authContext";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { SinPermisoComponent } from "../../Components/SinPermisoComponent/SinPermisoComponent";
import ListIcon from "@mui/icons-material/List";
import DeleteIcon from "@mui/icons-material/Delete";
import WidgetsIcon from '@mui/icons-material/Widgets';
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

const guidVista = "661ed266-3ea9-4b72-9987-9e0bb02d009b";

let opcionesPanelDeControl = [
  {
    id: 1,
    nombre: "Usuarios de sistema",
    icono: <PeopleAltIcon fontSize="small" />,
    url: "/usuarios",
    activo: 1,
  },
  {
    id: 2,
    nombre: "Empleados",
    icono: <PeopleOutlineIcon fontSize="small" />,
    url: "/empleados",
    activo: 1,
  },
  {
    id: 3,
    nombre: "Precios de mantenimiento",
    icono: <CleaningServicesIcon fontSize="small" />,
    url: "/preciosmantenimientoconfiguracion",
    activo: 1,
  },
  {
    id: 3,
    nombre: "Servicios - Ordenes de Servicio",
    icono: <ListIcon fontSize="small" />,
    url: "/serviciosordenesservicio",
    activo: 1,
  },
  {
    id: 4,
    nombre: "Auditoria",
    icono: <VerifiedUserIcon fontSize="small" />,
    url: "/auditoria",
    activo: 1,
  },
  {
    id: 4,
    nombre: "Eliminar Contratos",
    icono: <DeleteIcon fontSize="small" />,
    url: "/eliminarcontratos",
    activo: 1,
  },

  {
    id: 4,
    nombre: "Catálogo de Panteones",
    icono: <WidgetsIcon fontSize="small" />,
    url: "/catalogopanteones",
    activo: 1,
  },
];

export const PanelDeControlScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [permisoParaVisualizar, setPermisoParaVisualizar] =
    React.useState(false);

  const [checkPersmissions, setCheckPermissions] = React.useState([]);
  const [permisoReporte, setPermisoReporte] = React.useState(false);

  React.useEffect(() => {
    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "446f9976-bb14-409e-80f9-7a9eff4bedf5" &&
              permiso.allowed == 1
            ) {
              setPermisoParaVisualizar(true);
            }
          }
        }
      }
    );
  }, []);

  return (
    <>
      {permisoParaVisualizar == true ? (
        <div className="container">
          <div className="row row-cols-sm-3">
            {opcionesPanelDeControl.map((opcion, index) => (
              <div className="col mb-2" key={index}>
                <Link to={opcion.url}>
                  <div className={`card ${styles.cardMenu}`}>
                    <div className="card-body text-center">
                      <h4>{opcion.icono}</h4>
                      {opcion.nombre}
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <SinPermisoComponent />
      )}
    </>
  );
};
