import React from "react";
import { getServiciosOrdenesServicioDetalle } from "../../API/ApiServiciosOrdenesServicio";
import { AuthContext } from "./../../auth/authContext";
import AddIcon from "@mui/icons-material/Add";
import { getPagosPorOrdenServicio } from "../../API/ApiPagosOrdenes";
import { AddPagoOrdenServicioComponent } from "../AddPagoOrdenServicioComponent/AddPagoOrdenServicioComponent";
import { EditPagoOrdenServicioComponent } from "../EditPagoOrdenServicioComponent/EditPagoOrdenServicioComponent";
export const EditarPagosOrdenServicioComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [finalData, setFinalData] = React.useState([]);
  const [totalCuenta, setTotalCuenta] = React.useState();

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  React.useEffect(() => {
    getPagosPorOrdenServicio(user, props.finalData.folio).then((resultado) => {
      if ((resultado.status = 200)) {
        setFinalData(resultado.data);

        let pagoTotal = 0;
        for (const pago of resultado.data) {
          pagoTotal =
            pagoTotal +
            parseFloat(pago.montoPagado) +
            parseFloat(pago.abonoIva);
        }

        setTotalCuenta(pagoTotal);
      }
    });
  }, []);
  React.useEffect(() => {
    getPagosPorOrdenServicio(user, props.finalData.folio).then((resultado) => {
       
      if ((resultado.status = 200)) {
        setFinalData(resultado.data);
      }
    });
  }, [crudAction]);

  return (
    <>
      {props.agregarPagoAOrdenServicio == true ? (
        <>
          <div className="container-fluid">
            <div className="row row-cols-1">
              <div className="col text-end mt-3">
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => {
                    setCrudAction({
                      action: "add",
                      data: null,
                    });
                  }}
                >
                  <AddIcon fontSize="small" /> Agregar Pago
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        false
      )}

      <div className="container-fluid mt-3"></div>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-md-10">
            <div className="row row-cols-md-3">
              {finalData.map((pagos, index) => (
                <div className="col" key={index}>
                  <div className="card mb-2">
                    <div className="card-body">
                      Fecha: <strong>{pagos.fechaPago}</strong>
                      <br />
                      Recibo No: <strong>{pagos.noRecibo}</strong>
                      <br />
                      Monto Pagado:
                      <strong>
                        $
                        {parseFloat(pagos.montoPagado)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </strong>
                      <br />
                      Abono IVA:
                      <strong>
                        $
                        {parseFloat(pagos.abonoIva)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </strong>
                      <br />
                      Recibió:
                      <strong>{pagos.recibio}</strong>
                    </div>

                    <div className="card-footer">
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        {props.editarPagoAOrdenServicio == true ? (
                          <>
                            <button
                              className="btn btn-warning btn-sm me-md-2"
                              type="button"
                              onClick={() => {
                                setCrudAction({
                                  action: "edit",
                                  data: pagos,
                                });
                              }}
                            >
                              Editar Pago
                            </button>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-2">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <strong> Total Cuenta:</strong> ${" "}
                  {parseFloat(props.totalServicios)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
                  <br />
                  <strong>Total Pagado:</strong> ${" "}
                  {parseFloat(totalCuenta)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                  <hr />
                  <strong>
                    Saldo a pagar: ${" "}
                    {parseFloat(props.totalServicios - totalCuenta)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {crudAction.action == "add" ? (
        <AddPagoOrdenServicioComponent
          crudAction={crudAction}
          setCrudAction={setCrudAction}
          folio={props.finalData.folio}
        />
      ) : null}
      {crudAction.action == "edit" ? (
        <EditPagoOrdenServicioComponent
          crudAction={crudAction}
          setCrudAction={setCrudAction}
          folio={props.finalData.folio}
        />
      ) : null}
    </>
  );
};
