import React from "react";
import AccountBoxIcon from "@mui/icons-material/AccountBox";

import DoneAllIcon from "@mui/icons-material/DoneAll";
export const CardContratoHeaderComponent = (props) => {

  return (
    <div className="col">
      <div className="card mb-3">
        <div className="card-header strong">
          <div className="d-flex justify-content-between align-items-center">
            <span>
              <strong>
                {props.contrato.serie} - {props.contrato.noContrato}{" "}
              </strong>
              <br />
              <strong>{props.contrato.fechaContrato}</strong>
            </span>
            <span className="text-end">
              {props.contrato.status == 1 ? (
                <span className="badge rounded-pill bg-success">Activo</span>
              ) : null}
              {props.contrato.status == 2 ? (
                <span className="badge rounded-pill bg-secondary">Cancelado</span>
              ) : null}
              {props.contrato.status == 3 ? (
                <span className="badge rounded-pill bg-light">Inactivo</span>
              ) : null}
              {props.contrato.status == 4 ? (
                <span className="badge rounded-pill bg-danger">
                  <DoneAllIcon />
                  Otorgado
                </span>
              ) : null}
              {props.contrato.status == 5 ? (
                <span className="badge rounded-pill bg-secondary">
                  <DoneAllIcon /> Pagado
                </span>
              ) : null}
              {props.contrato.status == 6 ? (
                <span className="badge rounded-pill bg-secondary">
                  <DoneAllIcon /> Título
                </span>
              ) : null}
            </span>
          </div>
        </div>
        <div className="card-body">
          <h5>
            <AccountBoxIcon fontSize="inherit" /> &nbsp;
            {props.contrato.titular == "" || props.contrato.titular == null
              ? "Esperando nombre"
              : props.contrato.titular}
          </h5>
        </div>
        <div className="card-footer">
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <button
              className="btn btn-sm btn-success"
              onClick={() => {
                window.open("/contrato/" + props.contrato.id + "/false", "_blank");
              }}
              type="button"
            >
              Acceder a contrato
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
