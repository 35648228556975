import { API } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";

export const getPagosPorOrdenServicio = async (user, folio) => {
  let url = API + "orders/getPagosOrden/" + folio;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const nuevoPagoOrdenServicio = async (user, values) => {
  let url = API + "orders/nuevoPagoOrden/";

  values.noRecibo = values.letra + values.noRecibo;

  delete values.letra;

  debugger;

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);

    toast.error(error.response.data.message);
    debugger;

    return error;
  }
};

export const actualizarPagoOrdenServicio = async (user, values) => {
  let url = API + "orders/updatePago/" + values.id;

  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
