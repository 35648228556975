import React from "react";
import { AuthContext } from "../../auth/authContext";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import {
  GetAnaliticaPorPanteonAPI,
  GetEspaciosDePanteonAPI,
  GetEspaciosDePanteonPagosMantenimientoAPI,
} from "../../API/ApiPanteones";
import { Backdrop, Box, CircularProgress, Tab, TextField } from "@mui/material";
import ManIcon from "@mui/icons-material/Man";
import { AddCuerpoAEspacioComponent } from "../../Components/AddCuerpoAEspacioComponent/AddCuerpoAEspacioComponent";
import { AddAsignarEspacioAContratoComponent } from "../../Components/AddAsignarEspacioAContratoComponent/AddAsignarEspacioAContratoComponent";
import { AgregarEspaciosAPanteonComponent } from "../../Components/AgregarEspaciosAPanteonComponent/AgregarEspaciosAPanteonComponent";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { getAllEspacios } from "../../API/ApiEspacios";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { EliminarCuerpoAEspacioComponent } from "../../Components/EliminarCuerpoAEspacioComponent/EliminarCuerpoAEspacioComponent";
import { EditCuerpoAEspacioComponent } from "../../Components/EditCuerpoAEspacioComponent/EditCuerpoAEspacioComponent";
import { toast } from "react-toastify";
import MapIcon from "@mui/icons-material/Map";
import { MapaDeUbicacionComponent } from "../../Components/MapaDeUbicacionComponent/MapaDeUbicacionComponent";
export const DetalleDeEspacioScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [loading, setLoading] = React.useState(true);
  const [finalData, setFinalData] = React.useState([]);
  const [finalDataRendered, setFinalDataRendered] = React.useState([]);
  const [finalDataPagos, setFinalDataPagos] = React.useState([]);
  const [finalDataEspacios, setFinalDataEspacios] = React.useState([]);

  const [finalDataAnaliticaPanteon, setFinalDataAnaliticaPanteon] =
    React.useState([]);

  const [value, setValue] = React.useState("1");

  const [ordenarPor, setOrdenarPor] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  let { panteon } = useParams();

  panteon = JSON.parse(panteon);

  React.useEffect(() => {
    GetEspaciosDePanteonAPI(user, panteon).then((resultado) => {
      if (resultado.status === 200) {
        setFinalDataRendered(resultado.data);
        GetEspaciosDePanteonPagosMantenimientoAPI(user, panteon).then(
          (resultado) => {
            if (resultado.status === 200) {
              for (const espacio of resultado.data) {
                espacio.busqueda =
                  (espacio.letra == null ? "" : espacio.letra).toString() +
                  espacio.consecutivo;
                espacio.contratoCompleto =
                  espacio.contrato.length > 0
                    ? espacio.contrato[0].noContrato
                    : "";
              }

              setFinalData(resultado.data);
              setFinalDataPagos(resultado.data);
            }
          }
        );
        setLoading(false);
      } else {
        setFinalData([]);
        setLoading(false);
      }
    });

    GetAnaliticaPorPanteonAPI(user, panteon).then((resultado) => {
      if (resultado.status === 200) {
        setFinalDataAnaliticaPanteon(resultado.data);
        setLoading(false);
      } else {
        setFinalDataAnaliticaPanteon([]);
        setLoading(false);
      }
    });
  }, [crudAction]);

  React.useEffect(() => {
    let listo = false;
    getAllEspacios(user).then((resultado) => {
      if (resultado.status === 200) {
        setFinalDataEspacios(resultado.data);
      } else {
        setFinalDataEspacios([]);
      }
    });

    return () => {
      listo = true;
    };
  }, []);

  React.useEffect(() => {
    let holis = ordenarPor;

    if (ordenarPor === "Mayor") {
      setFinalDataEspacios([]);
      getAllEspacios(user).then((resultado) => {
        if (resultado.status === 200) {
          toast.info("Cambiando ordenamiento a mayor");
          var hola = [...finalDataPagos];
          var ordenador = hola.sort((a, b) => b.id - a.id);
          setFinalDataPagos(ordenador);
          setFinalDataEspacios(resultado.data);
        } else {
          setFinalDataEspacios([]);
        }
      });
    } else if (ordenarPor === "Menor") {
      setFinalDataEspacios([]);
      getAllEspacios(user).then((resultado) => {
        if (resultado.status === 200) {
          toast.info("Cambiando ordenamiento a menor");
          var hola = [...finalDataPagos];
          var ordenador = hola.sort((a, b) => a.id - b.id);
          setFinalDataPagos(ordenador);
          setFinalDataEspacios(resultado.data);
        } else {
          setFinalDataEspacios([]);
        }
      });
    }
  }, [ordenarPor]);

  if (loading === true) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" /> <br /> Cargando datos de panteon
      </Backdrop>
    );
  } else {
    return (
      <React.Fragment>
        <div className="container-fluid g-0 mt-3">
          <div className="row g-0">
            <div className="col">
              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <div
                  className="btn btn-sm btn-outline-success"
                  onClick={() => {
                    setCrudAction({
                      action: "crearEspacios",
                      data: panteon,
                    });
                  }}
                >
                  {" "}
                  <AddCircleOutlineIcon fontSize="inherit" /> Agregar Espacios
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid g-0 mt-3">
          <div className="row g-0">
            <div className="col">
              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <div
                  className="btn btn-sm btn-info"
                  onClick={() => {
                    setCrudAction({
                      action: "verMapa",
                      data: panteon,
                    });
                  }}
                >
                  {" "}
                  <MapIcon fontSize="inherit" /> Ver Mapa
                </div>
              </div>
            </div>
          </div>
        </div>

        {crudAction.action == "verMapa" ? (
          <MapaDeUbicacionComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            finalDataAnaliticaPanteon={finalDataAnaliticaPanteon}
          />
        ) : null}

        <div className="container-fluid g-0 mt-3">
          <div className="row">
            {finalDataAnaliticaPanteon[0]?.capillas?.totalConstruidas > 0 ? (
              <div className="col">
                <div className="card">
                  <div className="card-body">
                    <h5>Capillas</h5>
                    <strong>
                      {" "}
                      {finalDataAnaliticaPanteon[0]?.capillas?.totalConstruidas}
                    </strong>{" "}
                    Construidas
                    <br />
                    <strong>
                      {" "}
                      {finalDataAnaliticaPanteon[0]?.capillas?.disponibles}
                    </strong>{" "}
                    Disponibles
                    <br />
                    <strong>
                      {" "}
                      {finalDataAnaliticaPanteon[0]?.capillas?.ocupadas}
                    </strong>{" "}
                    Ocupadas
                  </div>
                </div>
              </div>
            ) : null}

            {finalDataAnaliticaPanteon[1]?.duplex?.totalConstruidas > 0 ? (
              <div className="col">
                <div className="card">
                  <div className="card-body">
                    <h5>Duplex</h5>
                    <strong>
                      {" "}
                      {finalDataAnaliticaPanteon[1]?.duplex?.totalConstruidas}
                    </strong>{" "}
                    Construidas
                    <br />
                    <strong>
                      {" "}
                      {finalDataAnaliticaPanteon[1]?.duplex?.disponibles}
                    </strong>{" "}
                    Disponibles
                    <br />
                    <strong>
                      {" "}
                      {finalDataAnaliticaPanteon[1]?.duplex?.ocupadas}
                    </strong>{" "}
                    Ocupadas
                  </div>
                </div>
              </div>
            ) : null}

            {finalDataAnaliticaPanteon[2]?.uniplex?.totalConstruidas > 0 ? (
              <div className="col">
                <div className="card">
                  <div className="card-body">
                    <h5>Uniplex</h5>
                    <strong>
                      {" "}
                      {
                        finalDataAnaliticaPanteon[2]?.uniplex?.totalConstruidas
                      }{" "}
                    </strong>{" "}
                    Construidas
                    <br />
                    <strong>
                      {" "}
                      {finalDataAnaliticaPanteon[2]?.uniplex?.disponibles}
                    </strong>{" "}
                    Disponibles
                    <br />
                    <strong>
                      {" "}
                      {finalDataAnaliticaPanteon[2]?.uniplex?.ocupadas}
                    </strong>{" "}
                    Ocupadas
                  </div>
                </div>
              </div>
            ) : null}

            {finalDataAnaliticaPanteon[3]?.mausoleo?.totalConstruidas > 0 ? (
              <div className="col">
                <div className="card">
                  <div className="card-body">
                    <h5>Mausoleo</h5>
                    <strong>
                      {" "}
                      {
                        finalDataAnaliticaPanteon[3]?.mausoleo?.totalConstruidas
                      }{" "}
                    </strong>{" "}
                    Construidas
                    <br />
                    <strong>
                      {" "}
                      {finalDataAnaliticaPanteon[3]?.mausoleo?.disponibles}
                    </strong>{" "}
                    Disponibles
                    <br />
                    <strong>
                      {" "}
                      {finalDataAnaliticaPanteon[3]?.mausoleo?.ocupadas}
                    </strong>{" "}
                    Ocupadas
                  </div>
                </div>
              </div>
            ) : null}

            {finalDataAnaliticaPanteon[4]?.murales?.totalConstruidas > 0 ? (
              <div className="col">
                <div className="card">
                  <div className="card-body">
                    <h5>Murales</h5>
                    <strong>
                      {" "}
                      {finalDataAnaliticaPanteon[4]?.murales?.totalConstruidas}
                    </strong>{" "}
                    Construidas
                    <br />
                    <strong>
                      {" "}
                      {finalDataAnaliticaPanteon[4]?.murales?.disponibles}
                    </strong>{" "}
                    Disponibles
                    <br />
                    <strong>
                      {" "}
                      {finalDataAnaliticaPanteon[4]?.murales?.ocupadas}
                    </strong>{" "}
                    Ocupadas
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="container-fluid g-0 mt-3 mb-3">
          <div className="row">
            <div className="col-sm-12">
              <TextField
                id="outlined-basic"
                label="Buscar espacio"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  let espacios = finalDataEspacios.slice();

                  setFinalDataEspacios([]);
                  if (e.target.value != "") {
                    let final = finalData.filter(
                      (espacio) => espacio.busqueda.toString() == e.target.value
                    );

                    setFinalDataEspacios(espacios);
                    setFinalDataPagos(final);
                  } else {
                    var final = [...finalData];
                    setFinalDataEspacios(espacios);
                    setFinalDataPagos(final);
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="container-fluid g-0 mt-3">
          <div className="row g-0">
            <div className="col d-grid gap-2 d-md-flex justify-content-md-end">
              <div
                className={`btn btn-sm ${
                  ordenarPor === "Mayor" ? "btn-success" : "btn-outline-success"
                }`}
                onClick={() => {
                  setOrdenarPor("Mayor");
                }}
              >
                Ordenar Mayor Primero
              </div>
              <div
                className={`btn btn-sm ${
                  ordenarPor === "Menor" ? "btn-success" : "btn-outline-success"
                }`}
                onClick={() => {
                  setOrdenarPor("Menor");
                }}
              >
                Ordenar Menor Primero
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid g-0 mt-3">
          <div className="row g-0">
            <div className="col">
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    {finalDataEspacios?.map((tipoespacio) => {
                      let finalEspacio = finalDataPagos.filter(
                        (buscar) => buscar.idtipoEspacio == tipoespacio.id
                      );

                      if (finalEspacio.length > 0) {
                        return (
                          <Tab
                            label={
                              tipoespacio.espacio +
                              " (" +
                              finalEspacio.length +
                              ") "
                            }
                            value={tipoespacio.id.toString()}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </TabList>
                </Box>

                {finalDataEspacios.map((tipoespacio) => (
                  <TabPanel value={tipoespacio.id.toString()}>
                    {finalDataPagos
                      .filter(
                        (buscar) => buscar.idtipoEspacio == tipoespacio.id
                      )
                      .map((espacio) => {
                        const NUMERIC_REGEXP = /[-]{0,1}[\d]*[.]{0,1}[\d]+/g;

                        if (
                          espacio.pagosMantto ==
                          "Sin fecha de asignación, imposible calcular"
                        ) {
                          espacio.porcentage = 0;
                        } else {
                          const resultado =
                            espacio.pagosMantto.match(NUMERIC_REGEXP);

                          console.log(resultado);

                          if (resultado[0] == 0) {
                            espacio.porcentage = 0;
                          } else {
                            let resultadoDos =
                              (parseFloat(resultado[0]) * 100) /
                              parseFloat(resultado[1]);

                            espacio.porcentage = Math.round(resultadoDos);
                          }
                        }

                        return (
                          <React.Fragment
                            key={espacio.consecutivo + espacio.letra}
                          >
                            <div className="row g-0">
                              <div className="card mb-2">
                                <div className="card-header d-flex justify-content-between d-flex align-items-center">
                                  <strong>
                                    {espacio.espacio} {espacio.letra}
                                    {espacio.consecutivo}
                                  </strong>
                                  <strong>
                                    {espacio.pagosMantto ===
                                    "Sin fecha de asignación, imposible calcular"
                                      ? "Sin fecha de asignación, imposible calcular"
                                      : espacio.porcentage + "% Pagado"}
                                  </strong>
                                  <span>
                                    {espacio.idcontrato == 0 ? (
                                      <span
                                        className="btn btn-sm btn-warning"
                                        onClick={() => {
                                          setCrudAction({
                                            action: "asignarEspacioAContrato",
                                            data: espacio,
                                          });
                                        }}
                                      >
                                        Sin contrato asignado
                                      </span>
                                    ) : (
                                      <>
                                        <span
                                          className="btn btn-sm btn-success"
                                          onClick={() => {
                                            if (
                                              espacio.porcentage > 0 ||
                                              espacio.personas.length > 0
                                            ) {
                                              toast.error(
                                                "No puedes editar el contrato, ya que este tiene pagos y / o finados, verifica la información"
                                              );
                                            } else {
                                              setCrudAction({
                                                action:
                                                  "asignarEspacioAContrato",
                                                data: espacio,
                                              });
                                            }
                                          }}
                                        >
                                          Contrato: {espacio.contrato[0]?.serie}
                                          {espacio.contrato[0]?.noContrato}
                                          <br /> Asignación:{" "}
                                          {espacio.fechaAsignacion}
                                        </span>
                                        <br />
                                      </>
                                    )}
                                  </span>
                                </div>
                                <div className="card-body">
                                  {espacio.personas.length === 0 ? (
                                    <>
                                      <i style={{ opacity: 0.5 }}>
                                        No hay Finados
                                      </i>
                                      {espacio.idcontrato !== 0 ? (
                                        <>
                                          <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-2">
                                            <div
                                              className="btn btn-sm btn-outline-success"
                                              onClick={() => {
                                                setCrudAction({
                                                  action: "agregarCuerpo",
                                                  data: espacio,
                                                });
                                              }}
                                            >
                                              + Agregar Finado
                                            </div>
                                          </div>
                                          <small>
                                            Comentarios:{" "}
                                            {espacio.comentarios == null ? (
                                              <i>Sin Comentarios</i>
                                            ) : (
                                              <strong>
                                                {" "}
                                                {espacio.comentarios}
                                              </strong>
                                            )}
                                          </small>
                                        </>
                                      ) : null}
                                    </>
                                  ) : (
                                    <>
                                      <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-2">
                                        <div
                                          className="btn btn-sm btn-outline-success"
                                          onClick={() => {
                                            setCrudAction({
                                              action: "agregarCuerpo",
                                              data: espacio,
                                            });
                                          }}
                                        >
                                          + Agregar Cuerpo
                                        </div>
                                      </div>
                                      <table class="table table-sm">
                                        <caption>
                                          <small>
                                            Total de finados:{" "}
                                            {espacio.personas.length}
                                          </small>
                                          <br />
                                          <small>
                                            Comentarios:{" "}
                                            {espacio.comentarios == null ? (
                                              <i>Sin Comentarios</i>
                                            ) : (
                                              <strong>
                                                {" "}
                                                {espacio.comentarios}
                                              </strong>
                                            )}
                                          </small>
                                        </caption>
                                        <thead>
                                          <tr>
                                            <th scope="col">#</th>
                                            <th
                                              scope="col"
                                              style={{ width: "300px" }}
                                            >
                                              Nombre
                                            </th>
                                            <th
                                              scope="col"
                                              style={{ width: "150px" }}
                                            >
                                              Inhumación
                                            </th>
                                            <th
                                              scope="col"
                                              style={{ width: "150px" }}
                                            >
                                              Exhumación
                                            </th>
                                            <th scope="col">Comentarios</th>
                                            <th
                                              scope="col"
                                              style={{ width: "50px" }}
                                            >
                                              Editar
                                            </th>
                                            <th
                                              scope="col"
                                              style={{ width: "50px" }}
                                            >
                                              Eliminar
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {espacio.personas.map(
                                            (cuerpo, index) => (
                                              <tr
                                                key={
                                                  cuerpo.id + cuerpo.updatedAt
                                                }
                                              >
                                                <th scope="row">{index + 1}</th>
                                                <td>{cuerpo.persona}</td>
                                                <td>
                                                  {cuerpo.fechaInhumacion}
                                                </td>
                                                <td>
                                                  {cuerpo.fechaExhumacion}
                                                </td>
                                                <td>{cuerpo.comentarios}</td>
                                                <td>
                                                  <div
                                                    className="btn btn-sm btn-warning"
                                                    onClick={() => {
                                                      setCrudAction({
                                                        action: "editarCuerpo",
                                                        data: cuerpo,
                                                      });
                                                    }}
                                                  >
                                                    Editar
                                                  </div>
                                                </td>
                                                <td>
                                                  <div
                                                    className="btn btn-sm btn-danger"
                                                    onClick={() => {
                                                      setCrudAction({
                                                        action:
                                                          "eliminarCuerpo",
                                                        data: cuerpo,
                                                      });
                                                    }}
                                                  >
                                                    Eliminar
                                                  </div>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                  </TabPanel>
                ))}
              </TabContext>
            </div>
          </div>
        </div>

        {crudAction.action == "agregarCuerpo" ? (
          <AddCuerpoAEspacioComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
          />
        ) : null}

        {crudAction.action == "editarCuerpo" ? (
          <EditCuerpoAEspacioComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
          />
        ) : null}

        {crudAction.action == "asignarEspacioAContrato" ? (
          <AddAsignarEspacioAContratoComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
          />
        ) : null}

        {crudAction.action == "crearEspacios" ? (
          <AgregarEspaciosAPanteonComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
          />
        ) : null}

        {crudAction.action == "eliminarCuerpo" ? (
          <EliminarCuerpoAEspacioComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
          />
        ) : null}
      </React.Fragment>
    );
  }
};
