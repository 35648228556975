import React from "react";
import styles from "./NavPrincipalComponent.module.css";
import imagenLogoNav from "../../Images/IsotipoBlanco.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { AuthContext } from "../../auth/authContext";
import jwt_decode from "jwt-decode";
import { types } from "./../../types/types";
import { SearchInAllWebComponent } from "../SearchInAllWebComponent/SearchInAllWebComponent";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { ConfigurationContext } from "./../../configuration/configurationContext";

import MenuIcon from "@mui/icons-material/Menu";
import { typesConfiguration } from "./../../types/configtypes";
const Capitalize = function (string) {
  const lower = string.toLowerCase();

  return lower.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

export const NavPrincipalComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);

  var decoded = jwt_decode(user.accessToken);

  const { configuration, dispatchConfiguration } =
    React.useContext(ConfigurationContext);

  return (
    <>
      <div
        className={
          process.env.REACT_APP_CONNECTION_MODE == "Development"
            ? `${styles.fondoMenuDevelopment} d-print-none`
            : `${styles.fondoMenu} d-print-none`
        }
      >
        <div className="container-fluid my-1 d-print-none">
          <div className="row d-flex align-items-center">
            <div
              className="col-sm-6 d-flex flex-row mb-3 d-flex align-items-center"
              style={{ cursor: "pointer" }}
            >
              <div
                style={{ cursor: "pointer", marginRight: 15 }}
                onClick={() => {
                  let tomandoValoresActuales = { ...configuration };
                  tomandoValoresActuales.menuAbierto =
                    !tomandoValoresActuales.menuAbierto;

                  dispatchConfiguration({
                    type: typesConfiguration.ingresarDatos,
                    payload: tomandoValoresActuales,
                  });
                }}
              >
                <MenuIcon />
              </div>
              <img
                src={imagenLogoNav}
                alt="Prueba"
                height={50}
                onClick={() => {
                  window.location.href = "/";
                }}
              />
            </div>

            <div className="col-sm-6 d-flex justify-content-end text-end">
              <SearchInAllWebComponent />
              <Popup
                trigger={
                  <span
                    className={`${styles.disableSelect}`}
                    style={{ cursor: "pointer", marginLeft: 10 }}
                  >
                    <strong>
                      <AccountBoxIcon fontSize="small" />{" "}
                      <small className="fw-normal">{decoded.nombre}</small>
                    </strong>
                    <KeyboardArrowDownIcon fontSize="small" />
                    <br></br>
                    <small className={`fw-light me-2 ${styles.username}`}>
                      {decoded.email}
                    </small>
                  </span>
                }
                position="bottom right"
              >
                <ul
                  className="list-group list-group-flush"
                  style={{ fontSize: "13px" }}
                >
                  {/*
                <li
                  className="list-group-item list-group-item-action"
                  onClick={() => {
                    window.open("https://intranet.tjagto.gob.mx/", "_blank");
                  }}
                >
                  <img
                    alt="Abrir Intranet"
                    src={imagenIntranet}
                    height={15}
                    className="pe-2"
                  />
                  Ir a Intranet
                </li>*/}
                  <li
                    className="list-group-item list-group-item-action ps-4"
                    onClick={() => {
                      dispatch({
                        type: types.logout,
                      });
                      window.location.href = "/";
                    }}
                  >
                    Cerrar Sesión
                  </li>
                </ul>
              </Popup>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
