import React from "react";
import { AuthContext } from "../../auth/authContext";
import { Alert } from "@mui/material";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { BuscadorDeEspaciosPorNombreComponent } from "../../Components/BuscadorDeEspaciosPorNombreComponent/BuscadorDeEspaciosPorNombreComponent";
import { toast } from "react-toastify";
import { getAllDetallePersona } from "../../API/ApiEspacios";
export const BuscadorEnEspaciosScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [numeroContratoEncontrado, setNumeroContratoEncontrado] =
    React.useState({});

  const [finalDataEncontrado, setFinalDataEncontrado] = React.useState({});

  React.useEffect(() => {
    if (Object.keys(numeroContratoEncontrado).length > 0) {
      toast.success("Buscando en espacios");

      getAllDetallePersona(user, numeroContratoEncontrado.id).then(
        (resultado) => {
          if (resultado.status === 200) {
            toast.success("Finado Encontrado");
            setFinalDataEncontrado(resultado.data);
          }
        }
      );
    }
  }, [numeroContratoEncontrado]);

  return (
    <React.Fragment>
      <hr />
      <div className="container-fluid g-0">
        <div className="row">
          <div className="col-12">
            <Alert severity="info">
              Puedes buscar a la persona por: Titular de contrato, Número de
              contrato, Nombre del finado
            </Alert>
          </div>
        </div>
      </div>

      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h5 className="m-0 p-0">
                  <PersonSearchIcon fontSize="inherit" /> Buscar
                </h5>
                <hr className="m-2 p-0" />

                <BuscadorDeEspaciosPorNombreComponent
                  numeroContratoEncontrado={numeroContratoEncontrado}
                  setNumeroContratoEncontrado={setNumeroContratoEncontrado}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h5 className="m-0 p-0">
                  <PersonSearchIcon fontSize="inherit" /> Resultados de búsqueda
                </h5>
                <hr className="m-2 p-0" />

                <table class="table">
                  <tbody>
                    <tr className="table-warning">
                      <td colspan="2">Datos de Finado: </td>
                    </tr>
                    <tr>
                      <th scope="row">Nombre Finado:</th>
                      <td>{finalDataEncontrado[0]?.detalleFinado.persona}</td>
                    </tr>
                    <tr>
                      <th scope="row">Fecha Inhumación:</th>
                      <td>
                        {finalDataEncontrado[0]?.detalleFinado.fechaInhumacion}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Fecha Exhumación:</th>
                      <td>
                        {finalDataEncontrado[0]?.detalleFinado.fechaExhumacion}
                      </td>
                    </tr>

                    <tr className="table-secondary">
                      <td colspan="2">Datos de Ubicación: </td>
                    </tr>
                    <tr>
                      <th scope="row">Ubicación:</th>
                      <td>{finalDataEncontrado[0]?.detalleUbicacion.nombre}</td>
                    </tr>
                    <tr>
                      <th scope="row">Tipo Espacio:</th>
                      <td>
                        {finalDataEncontrado[0]?.detalleTipoUbicacion.espacio}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Letra y Número:</th>
                      <td>
                        {finalDataEncontrado[0]?.detalleEspacio.letra}
                        {finalDataEncontrado[0]?.detalleEspacio.consecutivo}
                      </td>
                    </tr>

                    <tr className="table-success">
                      <td colspan="2">Datos de Contrato: </td>
                    </tr>
                    <tr>
                      <th scope="row">Número de Contrato:</th>
                      <td>
                        {finalDataEncontrado[0]?.detalleContrato.serie}{" "}
                        {finalDataEncontrado[0]?.detalleContrato.noContrato}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Titular:</th>
                      <td>{finalDataEncontrado[0]?.detalleContrato.titular}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="card-footer d-grid gap-2 d-md-flex justify-content-md-end">
                <div
                  className="btn btn-sm btn-success me-2"
                  onClick={() => {
                    window.open(
                      "/detalleubicacion/" +
                        finalDataEncontrado[0]?.detalleUbicacion.id,
                      "_self"
                    );
                  }}
                >
                  Abrir Espacio
                </div>
                <div
                  className="btn btn-sm btn-success"
                  onClick={() => {
                    window.open(
                      "/contrato/" +
                        finalDataEncontrado[0]?.detalleContrato.id +
                        "/false",
                      "_self"
                    );
                  }}
                >
                  Abrir Contrato
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
