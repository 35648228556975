import React from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { AuthContext } from "../../auth/authContext";
import {
  reporteCobranzaRangoFecha,
  reporteCuboComisionesContratos,
  reporteCuboComisionesOrdenes,
  reporteNomina,
  reporteNominaComisionesOrdenServicioAPI,
  reporteNominaComisionesPrevisionAPI,
  reporteNominaOrdenServicioCreditoContadoAPI,
  reporteNominaSueldos,
  reporteResumenSerie,
} from "../../API/ApiReportes";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { LoadingComponent } from "../../Components/LoadingComponent/LoadingComponent";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { SinPermisoComponent } from "../../Components/SinPermisoComponent/SinPermisoComponent";
import { BuscadorDeOrdenesDeServicioHookComponent } from "../../Components/BuscadorDeOrdenesDeServicioHookComponent/BuscadorDeOrdenesDeServicioHookComponent";
import { getAllEmpleadosParaSelect } from "../../API/ApiEmpleados";
import { BuscadorDeContratosParaPagoComponent } from "../../Components/BuscadorDeContratosParaPagoComponent/BuscadorDeContratosParaPagoComponent";
import { BuscadorDeContratosParaReporteComponent } from "../../Components/BuscadorDeContratosParaReporteComponent/BuscadorDeContratosParaReporteComponent";
import { toast } from "react-toastify";
import imageVitanova from "../../Images/LogotipoNegro.png";

import ReactToPrint from "react-to-print";
import { ButtonDownloadToXlsComponent } from "../../Components/ButtonDownloadToXlsComponent/ButtonDownloadToXlsComponent";

const guidVista = "ef433e95-e3b0-4caa-a533-3b599add112e";

const ComponentToPrint = React.forwardRef((props, ref) => {
  const { user, dispatch } = React.useContext(AuthContext);

  var total = 0;

  for (const comision of props.value.comisionesOrdenes) {
    total = total + parseFloat(comision.montoComision);
  }

  return (
    <div className="print-source " ref={ref}>
      <img alt="Vitanova Logo" src={imageVitanova} width="150" />
      <br />
      <br />
      <h5>Recibo de Comisiones Ordenes de Servicio</h5>
      <br />
      <br />
      <strong> Nombre </strong> {props.value.nombreEmpleado}
      <br />
      <br />
      <table className="table">
        <thead>
          <tr>
            <th scope="col"># Orden</th>
            <th scope="col">Comisión</th>
            <th scope="col">Observaciones</th>
          </tr>
        </thead>
        <tbody>
          {props.value.comisionesOrdenes.map((comision, index) => (
            <tr key={index}>
              <th scope="row">{comision.folio}</th>
              <td>
                {"$ "}{" "}
                {parseFloat(comision.montoComision)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              </td>
              <td>{comision.observaciones}</td>
            </tr>
          ))}
          <tr>
            <th scope="row"></th>
            <td>
              {"$ "}{" "}
              {parseFloat(total)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <div className="text-center">
        _____________________________________________________________
        <br />
        Firma
      </div>
      <br />
      <br />
      <br />
      <small style={{ fontSize: "10px" }}>Impreso por: {user.username}</small>
    </div>
  );
});

const ComponentToPrintWrapper = (props) => {
  const componentRef = React.useRef();

  return (
    <div className="row border-bottom py-3">
      <div className="col-md-5">{props.item.nombreEmpleado}</div>
      <div className="col-md-5">
        {props.item.comisionesOrdenes.map((comision) => (
          <>
            Folio:{" "}
            <a href={`contrato/${comision.folio}/false`}>{comision.folio}</a>{" "}
            <br /> Comisión {"$ "}{" "}
            {parseFloat(comision.montoComision)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            <br />
            {comision.observaciones}
            <hr />
          </>
        ))}
      </div>
      <div className="col-md-2">
        <ReactToPrint
          trigger={() => (
            <button type="primary" className="btn btn-sm btn-success">
              Imprimir Recibo
            </button>
          )}
          content={() => componentRef.current}
        />
        <ComponentToPrint ref={componentRef} value={props.item} />
      </div>
    </div>
  );
};

export const ReporteOrdenesServicioCreditoContado = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [reporteSerie, setReporteSerie] = React.useState("");
  const [reporteFechaInicial, setReporteFechaInicial] = React.useState("");
  const [reporteFechaFin, setReporteFechaFin] = React.useState("");
  const [numeroOrdenServicio, setNumeroOrdenServicio] = React.useState("");
  const [finalData, setFinalData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [checkPersmissions, setCheckPermissions] = React.useState([]);
  const [permisoParaVisualizar, setPermisoParaVisualizar] =
    React.useState(false);
  const [permisoReporte, setPermisoReporte] = React.useState(false);
  const [idempleadoFound, setIdempleadoFound] = React.useState("");
  const [granTotalComisionMostrar, setGranTotalComisionMostrar] =
    React.useState(0.0);

  const [granTotalTotalMostrar, setGranTotalTotalMostrar] = React.useState(0.0);

  const [listadoEmpleados, setListadoEmpleados] = React.useState([]);

  const [totalOrdenMostrar, setTotalOrdenMostrar] = React.useState(0.0);
  const [totalAbonadoMostrar, setTotalAbonadoMostrar] = React.useState(0.0);
  const [totalSaldoMostrar, setTotalSaldoMostrar] = React.useState(0.0);

  const todayInicio = new Date();
  const todayFin = new Date();
  const numberOfDaysToAdd = 1;
  const dateInicio = todayInicio.setDate(
    todayInicio.getDate() - numberOfDaysToAdd
  );
  const dateFin = todayFin.setDate(todayFin.getDate());

  const todayDate = new Date(dateInicio).toISOString().split("T")[0]; // yyyy-mm-dd

  const defaultValueFin = new Date(dateFin).toISOString().split("T")[0]; // yyyy-mm-dd

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "cc6a0a6e-3844-47d2-ab95-930d40355ca5" &&
              permiso.allowed == 1
            ) {
              setPermisoParaVisualizar(true);
            }
            if (
              permiso.guidFuncion == "cc6a0a6e-3844-47d2-ab95-930d40355ca5" &&
              permiso.allowed == 1
            ) {
              setPermisoReporte(true);
            }
          }
        }
      }
    );

    let listo = false;

    return () => {
      listo = true;
    };

    return () => {
      ready = true;
    };
  }, []);

  React.useEffect(() => {
    let listo = false;

    let granTotalOrdenMostrar = 0.0;
    let grantotalAbonadoMostrar = 0.0;
    let grantotalSaldoMostrar = 0.0;

    for (const ordenServicio of finalData) {
      granTotalOrdenMostrar =
        granTotalOrdenMostrar + parseFloat(ordenServicio.totalOrden);
      grantotalAbonadoMostrar =
        grantotalAbonadoMostrar + parseFloat(ordenServicio.totalAbonado);
      grantotalSaldoMostrar =
        grantotalSaldoMostrar + parseFloat(ordenServicio.saldoOrden);
    }

    setTotalOrdenMostrar(granTotalOrdenMostrar);
    setTotalAbonadoMostrar(grantotalAbonadoMostrar);
    setTotalSaldoMostrar(grantotalSaldoMostrar);
    return () => {
      listo = true;
    };
  }, [finalData]);

  React.useEffect(() => {
    let ready = false;

    getAllEmpleadosParaSelect(user).then((resultado) => {
      if (resultado.status == 200) {
        setListadoEmpleados(resultado.data);
      } else {
        setListadoEmpleados([]);
      }
    });

    return () => {
      ready = true;
    };
  }, []);

  return (
    <>
      {permisoParaVisualizar == true && permisoReporte == true ? (
        <>
          <div className="container-fluid mb-3 mt-2">
            <div className="row row-cols-md-3 d-flex align-items-center">
              <div className="col mb-2 d-none">
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="outlined-basic"
                  label="Fecha Inicio"
                  type="date"
                  variant="standard"
                  value={reporteFechaInicial}
                  onChange={(e) => {
                    setReporteFechaInicial(e.target.value);
                  }}
                />
              </div>
              <div className="col mb-2 d-none">
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="outlined-basic"
                  label="Fecha Fin"
                  type="date"
                  variant="standard"
                  value={reporteFechaFin}
                  onChange={(e) => {
                    setReporteFechaFin(e.target.value);
                  }}
                />
              </div>

              <div className="col mb-2 d-print-none">
                <button
                  className="btn btn-sm btn-success w-100"
                  onClick={() => {
                    setLoading(true);
                    reporteNominaOrdenServicioCreditoContadoAPI(user, {
                      fechaInicial: reporteFechaInicial,
                      fechaFin: reporteFechaFin,
                    }).then((resultado) => {
                      if (resultado.status == 200) {

                         
                        setFinalData(resultado.data);
                        setLoading(false);
                      }
                    });
                  }}
                >
                  {" "}
                  Órdenes pendientes de pago{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row row-cols-md-1">
              <div className="col" style={{ textAlign: "right" }}>
                <ButtonDownloadToXlsComponent
                  data={finalData}
                  fileName="Reporte Ordenes de Servicio Crédito Contado"
                />
              </div>
            </div>
                      
          </div>

          <div className="container-fluid d-none d-print-block">
            <div className="row">
              <div className="col-12">
                <h3>Reporte de órdenes de servicio Crédito</h3>
                <h6>Fecha: {todayDate}</h6>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row row-cols-1">
              <div className="col">
                <table className="table text-center">
                  <thead>
                    <tr>
                      <th scope="col">Folio</th>
                      <th scope="col">Nombre Finado</th>
                      <th scope="col">Total Orden</th>
                      <th scope="col">Total Abonado</th>
                      <th scope="col">Saldo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {finalData.map((orden, index) => (
                      <tr key={index}>
                        <th scope="row">
                          <a
                            rel="noreferrer"
                            target={"_blank"}
                            href={`/ordenservicio/${orden.folio}/false`}
                          >
                            {orden.folio}{" "}
                          </a>
                        </th>
                        <td>
                          {"+ "}
                          {orden.nombreFinado}
                        </td>
                        <td>
                          {"$ "}

                          {parseFloat(orden.totalOrden)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </td>
                        <td>
                          {"$ "}

                          {parseFloat(orden.totalAbonado)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </td>
                        <td>
                          {"$ "}

                          {parseFloat(orden.saldoOrden)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>Gran Total</td>
                      <td></td>
                      <td>
                        {"$ "}
                        {parseFloat(totalOrdenMostrar)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </td>
                      <td>
                        {"$ "}
                        {parseFloat(totalAbonadoMostrar)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </td>
                      <td>
                        {"$ "}
                        {parseFloat(totalSaldoMostrar)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>

          {loading == true ? <LoadingComponent /> : null}
        </>
      ) : (
        <SinPermisoComponent />
      )}
    </>
  );
};
