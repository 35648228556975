import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import { getAllUniplexByIdJardin } from "../../API/ApiUniplex";
import { AuthContext } from "../../auth/authContext";
import { AddUniplexScreenComponent } from "../../Components/AddUniplexScreenComponent/AddUniplexScreenComponent";
import AddIcon from "@mui/icons-material/Add";
import ErrorIcon from "@mui/icons-material/Error";
import { AsignarFallecidosAEspacioUniplexComponent } from "../../Components/AsignarFallecidosAEspacioUniplexComponent/AsignarFallecidosAEspacioUniplexComponent";
import { AsignarContratoAEspacioUniplexComponent } from "../../Components/AsignarContratoAEspacioUniplexComponent/AsignarContratoAEspacioUniplexComponent";
import { getAllDuplexByIdJardin } from "../../API/ApiDuplex";
import { AddDuplexScreenComponent } from "../../Components/AddDuplexScreenComponent/AddDuplexScreenComponent";
import { AsignarFallecidosAEspacioDuplexComponent } from "../../Components/AsignarFallecidosAEspacioDuplexComponent/AsignarFallecidosAEspacioDuplexComponent";
import { AsignarContratoAEspacioDuplexComponent } from "../../Components/AsignarContratoAEspacioDuplexComponent/AsignarContratoAEspacioDuplexComponent";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import CachedIcon from "@mui/icons-material/Cached";
import { AsignarFechaDeinicioDePagoaEspacioComponent } from "../../Components/AsignarFechaDeinicioDePagoaEspacioComponent/AsignarFechaDeinicioDePagoaEspacioComponent";
const guidVista = "6b203d5f-1db7-4edf-a929-d8e21c15e9aa";

export const DuplexScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(true);

  const [finalData, setFinalData] = React.useState({
    totalEspacios: 0,
    totalEspaciosConContrato: 0,
    totalPersonasMuertas: 0,
    data: [],
  });

  let { jardin } = useParams();

  jardin = JSON.parse(jardin);

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  const [asignarPersonaDuplex, setAsignarPersonaDuplex] = React.useState(false);
  const [agregarDuplex, setAgregarDuplex] = React.useState(false);
  const [asignarContratoDuplex, setAsignarContratoDuplex] =
    React.useState(false);

  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "a7cfa698-f62a-450f-8238-71ea6037bd06" &&
              permiso.allowed == 1
            ) {
              setAsignarPersonaDuplex(true);
            }
            if (
              permiso.guidFuncion == "a47914d0-3e38-4b1c-8580-2634a614a401" &&
              permiso.allowed == 1
            ) {
              setAgregarDuplex(true);
            }
            if (
              permiso.guidFuncion == "52dd3a5e-4825-4f87-994d-1fcad196f393" &&
              permiso.allowed == 1
            ) {
              setAsignarContratoDuplex(true);
            }
          }
        }
      }
    );

    return () => {
      ready = true;
    };
  }, []);

  React.useEffect(() => {
    getAllDuplexByIdJardin(user, jardin.id).then((resultado) => {
      if (resultado.status == 200) {
        let totalEspacios = resultado.data.length;
        let totalEspaciosConContrato = 0;
        let totalPersonasMuertas = 0;
        for (const espacio of resultado.data) {
          if (espacio.idcontrato !== 0) {
            totalEspaciosConContrato = totalEspaciosConContrato + 1;
          }

          if (espacio.fechaIn2 != null) {
            totalPersonasMuertas = totalPersonasMuertas + 1;
          }

          if (espacio.fechaIn1 != null) {
            totalPersonasMuertas = totalPersonasMuertas + 1;
          }
        }

        

        let armandoFinal = {
          totalEspacios: totalEspacios,
          totalEspaciosConContrato: totalEspaciosConContrato,
          totalPersonasMuertas: totalPersonasMuertas,
          data: resultado.data,
        };

        
        setFinalData(armandoFinal);
      } else {
        setFinalData([]);
      }
    });
  }, [crudAction]);

  return (
    <>
      {agregarDuplex == true ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 text-end my-3">
              <button
                className="btn btn-sm btn-success"
                onClick={() => {
                  setCrudAction({
                    action: "add",
                    data: null,
                  });
                }}
              >
                Agregar Duplex
              </button>
            </div>
          </div>
        </div>
      ) : null}

      <div className="container-fluid mt-3 mb-3">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="container-fluid">
                  <div className="row row-cols-md-4">
                    <div className="col text-center">
                      <h6>Espacios Creados</h6>
                      <strong> {finalData.totalEspacios}</strong>
                    </div>
                    <div className="col text-center">
                      <strong>
                        <h6>Espacios Con Contrato asignado</h6>
                        {finalData.totalEspaciosConContrato}
                      </strong>
                    </div>
                    <div className="col text-center">
                      <strong>
                        <h6>Espacios Sin Contrato</h6>
                        {finalData.totalEspacios -
                          finalData.totalEspaciosConContrato}
                      </strong>
                    </div>
                    <div className="col text-center">
                      <strong>
                        <h6>Fallecidos totales en espacio</h6>
                        {finalData.totalPersonasMuertas}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row row-cols-md-4">
          {finalData.data.map((uniplex, index) => (
            <div key={index} className="col">
              <div className="card mb-3">
                <div className="card-header d-flex justify-content-between d-flex align-items-center">
                  <strong>{uniplex.noPosicion}</strong>
                  <strong>
                    {uniplex.contrato == null ? (
                      <>
                        <ErrorIcon /> Sin Contrato
                      </>
                    ) : (
                      <a
                        href={`/contrato/${uniplex.idcontrato}/false`}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <i>
                          {uniplex.contrato == null
                            ? "Sin Contrato"
                            : uniplex.contrato}
                        </i>
                      </a>
                    )}
                  </strong>

                  {asignarContratoDuplex == true ? (
                    <div>
                      <button
                        className="btn btn-sm btn-success"
                        onClick={() => {
                          setCrudAction({
                            action: "asignarContrato",
                            data: uniplex,
                          });
                        }}
                      >
                        <span>
                          <AddIcon /> Contrato
                        </span>
                      </button>
                      <br />
                      <button
                        className="mt-2 btn btn-sm btn-outline-success"
                        onClick={() => {
                          setCrudAction({
                            action: "actualizarFecha",
                            data: uniplex,
                          });
                        }}
                      >
                        <span>
                          <CachedIcon /> Fecha
                        </span>
                      </button>
                    </div>
                  ) : null}
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush ">
                    <li className="list-group-item  w-100">
                    Asignación: {uniplex.fechaAsignacion == null
                        ? "Sin fecha"
                        : uniplex.fechaAsignacion}
                        <br />
                        <br />
                      {uniplex.personaEspacio1 == null
                        ? "No asignado"
                        : uniplex.personaEspacio1}
                      <br />
                      <small>
                        Inhumación:
                        {uniplex.fechaIn1 == null
                          ? "No asignado"
                          : uniplex.fechaIn1}
                      </small>
                      <br />
                      <small>
                        Exhumación:
                        {uniplex.fechaEx1 == null
                          ? "No asignado"
                          : uniplex.fechaEx1}
                      </small>

                      <hr className="m-0 p-0" />

                      {uniplex.personaEspacio1 == null
                        ? "No asignado"
                        : uniplex.personaEspacio2}
                      <br />
                      <small>
                        Inhumación:
                        {uniplex.fechaIn1 == null
                          ? "No asignado"
                          : uniplex.fechaIn2}
                      </small>
                      <br />
                      <small>
                        Exhumación:
                        {uniplex.fechaEx1 == null
                          ? "No asignado"
                          : uniplex.fechaEx2}
                      </small>
                    </li>
                  </ul>
                </div>
                <div className="card-footer">
                  {asignarPersonaDuplex == true ? (
                    <>
                      {uniplex.idcontrato == null ? (
                        <>
                          <button
                            className="btn btn-sm btn-success w-100"
                            onClick={() => {
                              setCrudAction({
                                action: "asignarContrato",
                                data: uniplex,
                              });
                            }}
                          >
                            <span>
                              <AddIcon /> Contrato
                            </span>
                          </button>
                        </>
                      ) : (
                        <>
                          {uniplex.persona == null ? (
                            <button
                              className="btn btn-sm btn-outline-success w-100"
                              onClick={() => {
                                setCrudAction({
                                  action: "asignFallecido",
                                  data: uniplex,
                                });
                              }}
                            >
                              Asignar
                            </button>
                          ) : (
                            <button
                              className="btn btn-sm btn-warning w-100"
                              onClick={() => {
                                setCrudAction({
                                  action: "asignFallecido",
                                  data: uniplex,
                                });
                              }}
                            >
                              Actualizar
                            </button>
                          )}
                        </>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {crudAction.action == "add" ? (
        <>
          <AddDuplexScreenComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            jardin={jardin}
          />
        </>
      ) : null}

      {crudAction.action == "asignFallecido" ? (
        <>
          <AsignarFallecidosAEspacioDuplexComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            jardin={jardin}
          />
        </>
      ) : null}
      {crudAction.action == "asignarContrato" ? (
        <>
          <AsignarContratoAEspacioDuplexComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            jardin={jardin}
          />
        </>
      ) : null}

      {crudAction.action == "actualizarFecha" ? (
        <>
          <AsignarFechaDeinicioDePagoaEspacioComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            idEspacio={2}
          />
        </>
      ) : null}
    </>
  );
};
