import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
export const SetWindowNameHelperComponent = (props) => {
  return (
    <>
      <Helmet>
        <title> Vita Nova - {props.windowName}</title>
        <link rel="canonical" href="https://www.tacobell.com/" />
      </Helmet>
    </>
  );
};
