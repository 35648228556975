import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  searchContracts,
  searchPagosContracts,
  searchPagosContractsFechas,
} from "../../API/ApiContratos";
import { AuthContext } from "../../auth/authContext";
import { searchServiceOrders } from "../../API/ApiOrdenesServicio";
import SearchIcon from "@mui/icons-material/Search";
import { ContactSupportOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";

export const BusquedaDePagosPorContratoComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [finalData, setFinalData] = React.useState([]);
  const [datoABuscar, setDatoABuscar] = React.useState();
  const [fechaInicial, setFechaInicial] = React.useState("");
  const [fechaFinal, setFechaFinal] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const [totalesFinales, setTotalesFinales] = React.useState([]);

  const [mostrarResultados, setMostrarResultados] = React.useState(false);

  React.useEffect(() => {
    var datosfinales = finalData;

    var arrayTotales = [
      { serie: "A", total: 0 },
      { serie: "AA", total: 0 },
      { serie: "B", total: 0 },
      { serie: "BB", total: 0 },
      { serie: "C", total: 0 },
      { serie: "D", total: 0 },
      { serie: "E", total: 0 },
      { serie: "F", total: 0 },
      { serie: "G", total: 0 },
      { serie: "H", total: 0 },
      { serie: "I", total: 0 },
      { serie: "J", total: 0 },
      { serie: "M", total: 0 },
      { serie: "MA", total: 0 },
      { serie: "MM", total: 0 },
      { serie: "S", total: 0 },
      { serie: "SM", total: 0 },
      { serie: "All", total: 0 },
    ];

    for (const recibo of finalData) {
      recibo.serie = recibo.serie.toString();
      var hola = arrayTotales.find((e) => e.serie == recibo.serie);
      hola.total = hola.total + parseFloat(recibo?.monto);
    }

    setTotalesFinales(arrayTotales);
  }, [finalData]);

  return (
    <>
      <div className="container-fluid g-0 d-print-none">
        <div className="row row-cols-md-2">
          <div className="col-sm-6">
            <TextField
              fullWidth
              label="Ingresa folio de pago"
              onChange={(e) => {
                setDatoABuscar(e.target.value);
              }}
              value={datoABuscar}
            />

            <button
              className="btn btn-success btn-sm w-100 mt-3 mt-3"
              onClick={() => {
                setLoading(true);
                searchPagosContracts(user, datoABuscar).then((resultado) => {
                  setMostrarResultados(false);
                  if (resultado.status == 200) {
                    setFinalData(resultado.data);
                    props.setFinalData(resultado.data);
                  } else {
                    setFinalData([]);
                    props.setFinalData([]);
                  }
                  setLoading(false);
                  setMostrarResultados(true);
                });
              }}
            >
              <SearchIcon />
              Buscar por folio
            </button>
          </div>
          <div className="col-sm-6">
            <div className="row">
              <div className="col-sm-6">
                <TextField
                  fullWidth
                  label="Fecha Inicial"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setFechaInicial(e.target.value);
                  }}
                  value={fechaInicial}
                />
              </div>
              <div className="col-sm-6">
                <TextField
                  fullWidth
                  label="Fecha Final"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setFechaFinal(e.target.value);
                  }}
                  value={fechaFinal}
                />
              </div>
            </div>
            <button
              className="btn btn-success btn-sm w-100 mt-3"
              onClick={() => {
                setLoading(true);
                setMostrarResultados(false);
                searchPagosContractsFechas(user, {
                  fechaInicio: fechaInicial,
                  fechaFin: fechaFinal,
                }).then((resultado) => {
                  if (resultado.status == 200) {
                    setFinalData(resultado.data);
                    props.setFinalData(resultado.data);
                  } else {
                    setFinalData([]);
                    props.setFinalData([]);
                  }
                  setMostrarResultados(true);
                  setLoading(false);
                });
              }}
            >
              <SearchIcon />
              Buscar por fechas
            </button>
          </div>
        </div>
      </div>
      <br />

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center">
            <div className="col d-print-block text-center mt-3 mb-4">
              <strong>
                Reporte de Recibos de pago del {fechaInicial} al {fechaFinal}{" "}
              </strong>
            </div>
          </div>
        </div>
      </div>

      {/* Aqui pongo el oculto */}
      {mostrarResultados == false ? null : (
        <div className="container-fluid">
          <div className="row row-cols-md-6">
            {totalesFinales.map((total, index) => (
              <React.Fragment key={index}>
                {total.serie == "C" ? null : (
                  <div className="col">
                    <div
                      className="card mb-1"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        toast.success(
                          "Estableciendo filtro: " + total.serie.toString()
                        );
                        props.setFiltroSeleccionado(total.serie.toString());
                      }}
                    >
                      <div className="card-body">
                        {total.serie == "All" ? (
                          <React.Fragment>
                            <strong>Mostrar Todos</strong>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <strong>{total.serie}</strong> ${" "}
                            {parseFloat(total.total)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}

      <br />

      {loading == true ? (
        <>
          <div className="alert alert-warning w-100 mt-3" role="alert">
            Estamos cargando la información por favor espera
          </div>
        </>
      ) : null}
    </>
  );
};
