import { API } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";

export const apiLoginUser = async (values) => {
  let url = API + "users/login";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.error(error);
    return error.response;
  }
};
export const apiRecoverPassword = async (values) => {
  let url = API + "users/recoverPassword";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });

    if (response.status === 200) {

      return response;
    }
  } catch (error) {


    toast.error(error.response.data.message);
    console.error(error);
    return error.response;
  }
};
