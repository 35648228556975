import React from "react";
import { getAllEspacios } from "../../API/ApiEspacios";
import { AuthContext } from "../../auth/authContext";
import { getAllPanteones } from "../../API/ApiPanteones";
import { Alert } from "@mui/material";
import { AddPanteonComponent } from "../../Components/AddPanteonComponent/AddPanteonComponent";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AgregarEspaciosAPanteonComponent } from "../../Components/AgregarEspaciosAPanteonComponent/AgregarEspaciosAPanteonComponent";

export const ListadoPanteonesDosPuntoCeroScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [cargando, setCargando] = React.useState(true);
  const [finalData, setFinalData] = React.useState([]);
  const [contador, setContador] = React.useState(0);

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  React.useEffect(() => {
    getAllEspacios(user).then((resultado) => {});
    getAllPanteones(user).then((resultado) => {
      if (resultado.status === 200) {
        setFinalData(resultado.data);
      } else {
        setFinalData([]);
      }
    });
  }, [contador, crudAction]);

  return (
    <React.Fragment>
      <div className="container-fluid g-0 mt-2 mb-2">
        <div className="row g-0">
          <div className="col-12">
            <Alert severity="success">
              Esta configuración servirá para crear ubicaciones, revisa muy bien
              la configuración
            </Alert>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-2">
        <div className="row">
          <div className="col-12 text-end">
            <button
              className="btn btn-success btn-sm"
              onClick={() => {
                setCrudAction({
                  action: "add",
                  data: null,
                });
              }}
            >
              Agregar Nueva ubicación
            </button>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-2">
        <div className="row">
          {finalData.map((panteon) => (
            <div className="col-12" key={panteon.id}>
              <div className="card mb-2">
                <div className="card-body">
                  <h6 className="m-0">{panteon.nombre.toUpperCase()}</h6>
                  <span className="m-0">{panteon.direccion.toUpperCase()}</span>
                  <br />
                  <span className="m-0">
                    {panteon.nombreEstado.toUpperCase()}
                  </span>
                  <br />
                  <span className="m-0">
                    {panteon.nombreCiudad.toUpperCase()}
                  </span>
                  <br />
                  <span className="m-0">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={` https://www.google.com/maps/search/?api=1&${encodeURIComponent(
                        panteon.direccion +
                          " " +
                          panteon.nombreEstado +
                          " " +
                          panteon.nombreCiudad
                      )}`}
                    >
                      Abrir en Google Maps
                    </a>
                  </span>
                </div>
                <div className="card-footer d-grid gap-2 d-md-flex justify-content-md-end">
                  <div
                    className="btn btn-sm btn-outline-success"
                    onClick={() => {
                      setCrudAction({
                        action: "crearEspacios",
                        data: panteon,
                      });
                    }}
                  >
                    {" "}
                    <AddCircleOutlineIcon fontSize="inherit" /> Agregar Espacios
                  </div>
                  <div
                    className="btn btn-sm btn-info"
                    onClick={() => {
                      window.open("/detalleubicacion/" + panteon.id, "_self");
                    }}
                  >
                    {" "}
                    <VisibilityIcon fontSize="inherit" /> Ver Ubicación
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {crudAction.action == "add" ? (
        <AddPanteonComponent
          crudAction={crudAction}
          setCrudAction={setCrudAction}
        />
      ) : null}

      {crudAction.action == "crearEspacios" ? (
        <AgregarEspaciosAPanteonComponent
          crudAction={crudAction}
          setCrudAction={setCrudAction}
        />
      ) : null}
    </React.Fragment>
  );
};
