import { API } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";
import { RepeatOneSharp } from "@mui/icons-material";

export const getAllOrdenesDeServicio = async (user) => {
  let url = API + "orders/getOrdenesServicio/";

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getDetalleOrdenesDeServicio = async (user, folio) => {
  let url = API + "orders/getDetalleOrden/" + folio;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      response.data = response.data[0];

      if (response.data.fechaNacimiento == "000-00-00") {
        response.data.fechaNacimiento = "";
      }
      if (response.data.fechaLPago == "000-00-00") {
        response.data.fechaLPago = "";
      }
      if (response.data.fechaMisa == null) {
        response.data.fechaMisa = "";
      }

      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const createNewOrder = async (user, values) => {
  let url = API + "orders/newOrder/";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const editOrdenServicio = async (user, values) => {
  let url = API + "orders/updateOrder/" + values.folio;

  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);

    return error;
  }
};

export const searchServiceOrders = async (user, idcontrato) => {
  let url = API + "orders/buscadorOrdenes/" + idcontrato;
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const addOrdenServicioCancelada = async (user, values) => {
  let url = API + "orders/cancelarFolioOrden";

  
  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};


export const eliminarFolioDesistema = async (user, id) => {
  let url = API + "contracts/eliminarFolioOrden/" + id;
  try {
    const response = await axios.delete(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
