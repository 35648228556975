import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { AppRouter } from "./Routers/AppRouter.js";
import { AuthContext } from "./auth/authContext";
import { authReducer } from "./auth/authReducer";
import { io } from "socket.io-client";
import "animate.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ReactDOM from "react-dom";
import "./Styles/fonts.css";
import "react-toastify/dist/ReactToastify.css";
import { ConfigurationContext } from "./configuration/configurationContext";
import { configurationReducer } from "./configuration/configurationReducer";
import { ActualizadorContext } from "./actualizadorContext/actualizadorContext.js";
const init = () => {
  return JSON.parse(localStorage.getItem("user")) || { logged: false };
};

const initConfiguration = () => {
  return (
    JSON.parse(localStorage.getItem("configurationVitanova")) || {
      menuAbierto: true,
    }
  );
};

const contadorActualizadorRender = () => {
  return { contadorActualizar: 0 };
};

function App() {
  const [user, dispatch] = React.useReducer(authReducer, {}, init);

  const [actualizarVentanaContext, dispatchActualizarVentanaContext] =
    React.useState(contadorActualizadorRender);

  const [configuration, dispatchConfiguration] = React.useReducer(
    configurationReducer,
    {},
    initConfiguration
  );

  React.useEffect(() => {
    if (!user) return;
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  React.useEffect(() => {
    if (!configuration) return;
    localStorage.setItem(
      "configurationVitanova",
      JSON.stringify(configuration)
    );
  }, [configuration]);

  return (
    <React.Fragment>
      <HelmetProvider>
        <AuthContext.Provider value={{ user, dispatch }}>
          <ConfigurationContext.Provider
            value={{ configuration, dispatchConfiguration }}
          >
            <ActualizadorContext.Provider
              value={{
                actualizarVentanaContext,
                dispatchActualizarVentanaContext,
              }}
            >
              <ToastContainer />
              <AppRouter />
            </ActualizadorContext.Provider>
          </ConfigurationContext.Provider>
        </AuthContext.Provider>
      </HelmetProvider>
    </React.Fragment>
  );
}
ReactDOM.hydrate(App, document.getElementById("root"));

export default App;
